import React, { useState } from "react";
import Header from "../../../components/Header";
import JoinPopup from "../../../components/JoinPopup";
import Footer from "../../../components/Footer";
import Contact from "../../../components/Contact";
import Content from "./Content";

const Mentorship = () => {
  const [showJoinPopup, setShowJoinPopup] = useState(false);

  const handleJoinClick = () => {
    setShowJoinPopup(true);
  };

  const handleClosePopup = () => {
    setShowJoinPopup(false);
  };
  return (
    <>
      <Header onJoinClick={handleJoinClick} />
      <Content />
      <Contact />
      <Footer />
      <JoinPopup show={showJoinPopup} onClose={handleClosePopup} />
    </>
  );
};

export default Mentorship;
