import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const ViewUsers = () => {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(
          "https://apnbackend.onrender.com/api/users"
        );
        const data = await response.json();
        console.log("Fetched data:", data);
        if (Array.isArray(data)) {
          setUsers(data);
        } else {
          console.error("Expected an array but got:", data);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const exportPDF = () => {
    const doc = new jsPDF();
    const tableColumn = [
      "First Name",
      "Last Name",
      "Email",
      "Team",
      "Message",
      "Country",
      "Gender",
      "Profession",
      "Phone",
      "Agree to WhatsApp",
      "Agree to Newsletter",
      "Payment Confirmed",
      "Registration Time",
    ];
    const tableRows = [];

    users.forEach((user) => {
      const userData = [
        user.firstName,
        user.lastName,
        user.email,
        user.team,
        user.message,
        user.country,
        user.gender,
        user.profession,
        user.phone,
        user.agreeWhatsApp ? "Yes" : "No",
        user.agreeNewsletter ? "Yes" : "No",
        user.paymentConfirmed ? "Yes" : "No",
        new Date(user.createdAt).toLocaleString(),
      ];
      tableRows.push(userData);
    });

    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    doc.text("Registered Users", 14, 15);
    doc.save("registered_users.pdf");
  };

  const goBackToDashboard = () => {
    navigate("/apn/admin/dashboard"); // Ensure this path matches your route in AdminPage
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center py-10">
      <div className="w-full max-w-6xl p-8 bg-white shadow-lg rounded-lg">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-800">Registered Users</h2>
          <div>
            <button
              onClick={exportPDF}
              className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-300 mr-2"
            >
              Export to PDF
            </button>
            <button
              onClick={goBackToDashboard}
              className="bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded transition duration-300"
            >
              Back to Dashboard
            </button>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b text-start">First Name</th>
                <th className="py-2 px-4 text-start border-b">Last Name</th>
                <th className="py-2 px-4 text-start border-b">Email</th>
                <th className="py-2 px-4 text-start border-b">Team</th>
                <th className="py-2 px-4 text-start border-b">Message</th>
                <th className="py-2 px-4 text-start border-b">Country</th>
                <th className="py-2 px-4 text-start border-b">Gender</th>
                <th className="py-2 px-4 text-start border-b">Profession</th>
                <th className="py-2 px-4 text-start border-b">Phone</th>
                <th className="py-2 px-4 text-start border-b">
                  Agree to WhatsApp
                </th>
                <th className="py-2 px-4 text-start border-b">
                  Agree to Newsletter
                </th>
                <th className="py-2 px-4 text-start border-b">
                  Payment Confirmed
                </th>
                <th className="py-2 px-4 text-start border-b">
                  Registration Time
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  <td className="py-2 px-4 text-start border-b">
                    {user.firstName}
                  </td>
                  <td className="py-2 px-4 text-start border-b">
                    {user.lastName}
                  </td>
                  <td className="py-2 px-4 text-start border-b">
                    {user.email}
                  </td>
                  <td className="py-2 px-4 text-start border-b">{user.team}</td>
                  <td className="py-2 px-4 text-start border-b">
                    {user.message}
                  </td>
                  <td className="py-2 px-4 text-start border-b">
                    {user.country}
                  </td>
                  <td className="py-2 px-4 text-start border-b">
                    {user.gender}
                  </td>
                  <td className="py-2 px-4 text-start border-b">
                    {user.profession}
                  </td>
                  <td className="py-2 px-4 text-start border-b">
                    {user.phone}
                  </td>
                  <td className="py-2 px-4 text-start border-b">
                    {user.agreeWhatsApp ? "Yes" : "No"}
                  </td>
                  <td className="py-2 px-4 text-start border-b">
                    {user.agreeNewsletter ? "Yes" : "No"}
                  </td>
                  <td className="py-2 px-4 text-start border-b">
                    {user.paymentConfirmed ? "Yes" : "No"}
                  </td>
                  <td className="py-2 px-4 text-start border-b">
                    {new Date(user.createdAt).toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ViewUsers;
