import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getData } from "country-list";

const countryData = getData();

const MembershipContent = () => {
  const carouselImages = [
    "/images/caromemebership3.avif",
    "/images/caromemebership1.avif",
    "/images/caromemebership2.avif",
  ];

  const teams = [
    {
      title: "Welcome to Sweden team",
      description:
        "This team is dedicated to driving the Welcome to Sweden Program, a program focused on helping newly migrated expats/individuals to seamlessly integrate into Swedish society. This program involves support services, cultural integration events, and assistance for newcomers.",
      image: "/images/welcome_team.jpg",
    },
    {
      title: "Events and Programs ",
      description:
        "Responsible for planning and executing various events while fostering strategic collaborations with external partners.",
      image: "/images/events_team.avif",
    },
    {
      title: "Mentorship team",
      description:
        "Specialized group focused on facilitating meaningful mentorship relationships and professional development opportunities.",
      image: "/images/manstandinfront.jpg",
    },
    {
      title: "Finance team",
      description:
        "This team manages budgeting, financial planning, accounting, and reporting, playing a pivotal role in strategic decision-making.",
      image: "/images/finance_team.jpg",
    },
    {
      title: "ProTalk series team",
      description:
        "A dynamic group overseeing the planning and execution of our engaging talk show events.",
      image: "/images/audiences.jpg",
    },
    {
      title: "Partnership team",
      description:
        "This team has the mandate of identifying partners that align with our values to help APN succeed in our plans. Partners can collaborate with us in various ways such as offering us venue space to host our events, financial assistance to support our administrative needs, providing us speakers to speak at our events and instill knowledge in our members etc. We also have a wealth of experience within the member base of APN and many companies as well as academic institutions can benefit from the experiences of our members in various ways i.e. professionally, culturally, and socially. With many companies taking DEI initiatives seriously, we have a wealth of experience in how we have experienced DEI matters in our various workplaces and can share our knowledge to help such teams create great places to work. So we see partnership as a mutually beneficial relationship and we welcome you to collaborate with us to build a more welcoming professional environment.",
      image: "/images/partnership_team.avif",
    },
    {
      title: "Community management team",
      description:
        "This team oversees communication channels, moderates discussions, and actively engages with community members.",
      image: "/images/community_team.avif",
    },
  ];

  const [expanded, setExpanded] = useState(Array(teams.length).fill(false));

  const toggleExpand = (index) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };

  const truncateText = (text, length) => {
    if (text.length <= length) return text;
    return text.substring(0, length) + "...";
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    country: "",
    gender: "",
    profession: "",
    message: "",
    agreeWhatsApp: false,
    agreeNewsletter: false,
    team: "welcome",
    paymentConfirmed: false,
  });

  const [page, setPage] = useState(1);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone: value,
    });
  };

  const validateForm = () => {
    const {
      firstName,
      lastName,
      email,
      phone,
      country,
      gender,
      profession,
      team,
      paymentConfirmed,
    } = formData;

    if (page === 1 && (!firstName || !lastName || !email)) {
      toast.error("Please fill in all required fields on this page");
      return false;
    }
    if (page === 2 && (!phone || !country || !gender)) {
      toast.error("Please fill in all required fields on this page");
      return false;
    }
    if (page === 3 && !profession) {
      toast.error("Please fill in all required fields on this page");
      return false;
    }
    if (page === 4 && !team) {
      toast.error("Please fill in all required fields on this page");
      return false;
    }
    if (page === 5 && !paymentConfirmed) {
      toast.error("Please confirm your payment");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      console.log("Submitting form data:", formData);
      const response = await fetch(
        "https://apnbackend.onrender.com/api/users/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const result = await response.json();

      console.log("Server response:", result);

      if (response.ok) {
        toast.success(result.message || "User registered successfully");
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          country: "",
          gender: "",
          profession: "",
          message: "",
          agreeWhatsApp: false,
          agreeNewsletter: false,
          team: "welcome",
          paymentConfirmed: false,
        });
        setPage(1);
      } else {
        toast.error(result.error || "Failed to register user");
      }
    } catch (error) {
      toast.error("Error: " + error.message);
      console.error("Error during submission:", error);
    }
  };

  const renderPageOne = () => (
    <>
      <input
        type="text"
        name="firstName"
        placeholder="First Name"
        className="w-full mb-4 p-2 border rounded"
        value={formData.firstName}
        onChange={handleChange}
      />
      <input
        type="text"
        name="lastName"
        placeholder="Last Name"
        className="w-full mb-4 p-2 border rounded"
        value={formData.lastName}
        onChange={handleChange}
      />
      <input
        type="email"
        name="email"
        placeholder="Email"
        className="w-full mb-4 p-2 border rounded"
        value={formData.email}
        onChange={handleChange}
      />
      <div className="flex justify-between">
        <button
          type="button"
          className="w-full bg-orange-500 text-white p-2 rounded"
          onClick={() => {
            if (validateForm()) setPage(2);
          }}
        >
          Next
        </button>
      </div>
    </>
  );

  const renderPageTwo = () => (
    <>
      <PhoneInput
        country={"us"}
        value={formData.phone}
        onChange={handlePhoneChange}
        className="w-full mb-4 p-2 border rounded"
        enableSearch={true}
      />
      <select
        name="country"
        className="w-full mb-4 p-2 border rounded cursor-pointer"
        value={formData.country}
        onChange={handleChange}
      >
        <option value="">Select Country of Origin</option>
        {countryData.map((country) => (
          <option key={country.code} value={country.name}>
            {country.name}
          </option>
        ))}
      </select>
      <select
        name="gender"
        className="w-full mb-4 p-2 border rounded cursor-pointer"
        value={formData.gender}
        onChange={handleChange}
      >
        <option value="">Select Gender</option>
        <option value="male">Male</option>
        <option value="female">Female</option>
        <option value="other">Other</option>
      </select>
      <div className="flex justify-between flex-col-reverse gap-2">
        <button
          type="button"
          className="w-full bg-gray-500 text-white p-2 rounded mb-2"
          onClick={() => setPage(1)}
        >
          Previous
        </button>
        <button
          type="button"
          className="w-full bg-orange-500 text-white p-2 rounded"
          onClick={() => {
            if (validateForm()) setPage(3);
          }}
        >
          Next
        </button>
      </div>
    </>
  );

  const renderPageThree = () => (
    <>
      <input
        type="text"
        name="profession"
        placeholder="Profession"
        className="w-full mb-4 p-2 border rounded"
        value={formData.profession}
        onChange={handleChange}
      />
      <textarea
        name="message"
        placeholder="Leave us a message..."
        className="w-full mb-4 p-2 border rounded"
        value={formData.message}
        onChange={handleChange}
      />
      <div className="flex justify-between flex-col-reverse gap-2">
        <button
          type="button"
          className="w-full bg-gray-500 text-white p-2 rounded mb-2"
          onClick={() => setPage(2)}
        >
          Previous
        </button>
        <button
          type="button"
          className="w-full bg-orange-500 text-white p-2 rounded"
          onClick={() => {
            if (validateForm()) setPage(4);
          }}
        >
          Next
        </button>
      </div>
    </>
  );

  const renderPageFour = () => (
    <>
      <select
        name="team"
        className="w-full mb-4 p-2 border rounded cursor-pointer"
        value={formData.team}
        onChange={handleChange}
      >
        <option value="">Select Team</option>
        {teams.map((team, index) => (
          <option key={index} value={team.title}>
            {team.title}
          </option>
        ))}{" "}
        <option value="No Team">No Team</option>
      </select>
      <div className="w-full mb-4 p-2 border rounded">
        <label className="flex items-center">
          <input
            type="checkbox"
            name="agreeWhatsApp"
            checked={formData.agreeWhatsApp}
            onChange={handleChange}
            className="form-checkbox"
          />
          <span className="ml-2">
            I agree to be added to and receive updates via the APN WhatsApp
            group
          </span>
        </label>
      </div>
      <div className="w-full  mb-4 p-2 border rounded">
        <label className="flex items-center">
          <input
            type="checkbox"
            name="agreeNewsletter"
            checked={formData.agreeNewsletter}
            onChange={handleChange}
            className="form-checkbox"
          />
          <span className="ml-2">Agree to receive the APN Newsletter</span>
        </label>
      </div>
      <div className="flex justify-between flex-col-reverse gap-2">
        <button
          type="button"
          className="w-full bg-gray-500 text-white p-2 rounded mb-2"
          onClick={() => setPage(3)}
        >
          Previous
        </button>
        <button
          type="button"
          className="w-full bg-orange-500 text-white p-2 rounded"
          onClick={() => {
            if (validateForm()) setPage(5);
          }}
        >
          Next
        </button>
      </div>
    </>
  );

  const renderPageFive = () => (
    <>
      <div className="flex flex-col gap-4 my-4 text-start">
        <h1 className="text-4xl font-bold">How to pay for your membership</h1>

        <p>
          Please use the same name in your registration as a reference when
          making the Swish payment.{" "}
          <span className="text-orange-500 font-semibold">
            Kindly be aware that the annual membership fee is 300 SEK/year for
            employed adults.
          </span>
        </p>

        <p className="text-orange-500 font-semibold">
          Students and unemployed individuals qualify for a 50% discount,
          reducing the fee to 150 SEK/year.
        </p>

        <p className="text-orange-500 font-semibold">
          Note that this membership is valid for the calendar year, regardless
          of when you apply; for instance, if you pay for 2023, it covers the
          entire year, and subsequent payments will be for the following years,
          such as 2024, and so forth.
        </p>
      </div>

      <div className="flex justify-between flex-col-reverse gap-2">
        <button
          type="button"
          className="w-full bg-gray-500 text-white p-2 rounded mb-2"
          onClick={() => setPage(4)}
        >
          Previous
        </button>
        <button
          type="button"
          className="w-full bg-orange-500 text-white p-2 rounded"
          onClick={() => setPage(6)}
        >
          Next
        </button>
      </div>
    </>
  );

  const renderPageSix = () => (
    <>
      <div className="text-center mb-4">
        <h3 className="text-2xl font-bold mb-4">Payment</h3>
        <p className="text-lg mb-4">
          Please use the QR code below to pay the membership fee of 300 SEK.
        </p>
        <img
          loading="lazy"
          src="/images/donate.webp"
          alt="QR Code for Swish payment"
          className="mx-auto mb-4"
        />
        <p className="text-lg mb-4">Swish Number: 1233099173</p>
      </div>
      <div className="w-full mb-4 p-2 border rounded flex ">
        <label className="flex items-center">
          <input
            type="checkbox"
            name="paymentConfirmed"
            checked={formData.paymentConfirmed}
            onChange={handleChange}
            className="form-checkbox"
          />
          <span className="ml-2">I have completed the payment</span>
        </label>
      </div>
      <div className="flex justify-between flex-col-reverse gap-2">
        <button
          type="button"
          className="w-full bg-gray-500 text-white p-2 rounded mb-2"
          onClick={() => setPage(5)}
        >
          Previous
        </button>
        <button
          type="submit"
          className="w-full bg-orange-500 text-white p-2 rounded"
        >
          Submit
        </button>
      </div>
    </>
  );

  return (
    <section className="pb-20 bg-gray-100">
      <div className="container  mx-auto">
        <div className="text-center mb-8 ">
          <div className="relative mb-8 ">
            <Carousel
              showArrows={false}
              showThumbs={false}
              showStatus={false}
              infiniteLoop
              autoPlay
              interval={3000}
              className="h-[600px] lg:h-[400px]"
            >
              {carouselImages.map((image, index) => (
                <div key={index}>
                  <img
                    loading="lazy"
                    src={image}
                    alt={`carousel-${index}`}
                    className="h-[600px] lg:h-[400px] object-cover w-full"
                  />
                </div>
              ))}
            </Carousel>
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-80 flex-col gap-2 text-white">
              <div className="w-[90%] mx-auto">
                <h2 className="text-4xl font-bold mb-4">Join Our Community</h2>
                <p className="text-lg">
                  APN Sweden, the leading community for African professionals,
                  is now open for membership. Joining APN involves a commitment
                  to contribute to the ongoing development of African
                  professionals in Sweden. Members gain access to a dynamic
                  platform, resources, and a supportive community. We invite
                  professionals from Africa, those of African descent, and
                  allies to officially join APN and be part of this
                  collaborative journey of growth.
                </p>
              </div>
            </div>
          </div>

          <h2 className="text-4xl font-bold my-6">
            Our Community Teams You Might Like To Join..
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16 w-[90%] mx-auto">
            {teams.map((team, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded hover:shadow-md shadow-xl cursor-pointer transform transition duration-300 hover:scale-105 h-fit"
              >
                <div className="relative mb-4">
                  <img
                    loading="lazy"
                    src={team.image}
                    alt={team.title}
                    className="w-full h-auto rounded-lg shadow-md"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center rounded">
                    <button className="bg-[#f46524] text-white font-bold py-2 px-4 rounded">
                      Read More
                    </button>
                  </div>
                </div>
                <h3 className="text-2xl font-bold mb-2">{team.title}</h3>
                <p className="text-lg">
                  {expanded[index]
                    ? team.description
                    : truncateText(team.description, 130)}
                </p>
                {team.description.length > 130 && (
                  <button
                    onClick={() => toggleExpand(index)}
                    className="text-[#f46524] font-bold"
                  >
                    {expanded[index] ? "Read Less" : "Read More"}
                  </button>
                )}
              </div>
            ))}
          </div>

          <div className="bg-white p-8 rounded shadow-lg w-[90%] md:w-[60%] mx-auto">
            <h3 className="text-2xl font-bold mb-6">Signup</h3>
            <form onSubmit={handleSubmit} className="grid gap-4">
              {page === 1 && renderPageOne()}
              {page === 2 && renderPageTwo()}
              {page === 3 && renderPageThree()}
              {page === 4 && renderPageFour()}
              {page === 5 && renderPageFive()}
              {page === 6 && renderPageSix()}
            </form>

            <ToastContainer />
          </div>

          <div className="mt-16 w-[90%] mx-auto flex md:flex-row  flex-col gap-4 items-center">
            <div className="flex flex-col gap-4 my-5 text-start md:w-[60%]">
              <h1 className="text-4xl font-bold">
                How to pay for your membership
              </h1>

              <p>
                Please use the same name in your registration as a reference
                when making the Swish payment.{" "}
                <span className="text-orange-500 font-semibold">
                  Kindly be aware that the annual membership fee is 300 SEK/year
                  for employed adults.
                </span>
              </p>

              <p className="text-orange-500 font-semibold">
                Students and unemployed individuals qualify for a 50% discount,
                reducing the fee to 150 SEK/year.
              </p>

              <p className="text-orange-500 font-semibold">
                Note that this membership is valid for the calendar year,
                regardless of when you apply; for instance, if you pay for 2023,
                it covers the entire year, and subsequent payments will be for
                the following years, such as 2024, and so forth.
              </p>
            </div>
            <div className="flex flex-col gap-4  md:w-[40%]">
              <img
                loading="lazy"
                src="/images/donate.webp"
                alt="QR Code for Swish payment"
                className="mx-auto mb-4"
              />
              <p className="text-lg">Swish Number: 1233099173</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MembershipContent;
