import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const ManagePrograms = () => {
  const [programs, setPrograms] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await axios.get(
          "https://apnbackend.onrender.com/api/programs"
        );
        setPrograms(response.data);
      } catch (error) {
        console.error("Error fetching programs:", error);
      }
    };
    fetchPrograms();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/programs/${id}`);
      setPrograms(programs.filter((program) => program._id !== id));
      alert("Program deleted successfully!");
    } catch (error) {
      console.error("Error deleting program:", error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center py-10">
      <div className="w-full max-w-4xl p-8 bg-white shadow-lg rounded-lg">
        <div className="flex items-center justify-between">
          <h2 className="text-2xl font-bold mb-6">Manage Programs</h2>
          <button
            type="button"
            onClick={() => navigate("/apn/admin/dashboard")}
            className="bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded transition duration-300"
          >
            Back to Dashboard
          </button>
        </div>
        <table className="w-full bg-white shadow-md rounded-lg">
          <thead>
            <tr>
              <th className="py-2 px-4">Title</th>
              <th className="py-2 px-4">Location</th>
              <th className="py-2 px-4">Date</th>
              <th className="py-2 px-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {programs.map((program) => (
              <tr key={program._id}>
                <td className="py-2 px-4">{program.title}</td>
                <td className="py-2 px-4">{program.location}</td>
                <td className="py-2 px-4">
                  {new Date(program.date).toLocaleDateString()}
                </td>
                <td className="py-2 px-4">
                  <Link
                    to={`/apn/admin/edit-program/${program._id}`}
                    className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-1 px-2 rounded mr-2"
                  >
                    Edit
                  </Link>
                  <button
                    onClick={() => handleDelete(program._id)}
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManagePrograms;
