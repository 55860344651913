import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  FaChalkboardTeacher,
  FaUsers,
  FaBrain,
  FaNetworkWired,
} from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

const About = () => {
  const carouselImages = [
    "/images/carousel1.avif",
    "/images/carousel2.avif",
    "/images/carousel3.avif",
  ];

  const objectives = [
    {
      title: "Professional Development",
      icon: <FaChalkboardTeacher size={40} className="mb-4 text-white" />,
      items: [
        "Workshops around topics of interest to members",
        "Recruitment: job fairs & referral system",
        "Mentorship program",
      ],
    },
    {
      title: "Community Engagement",
      icon: <FaUsers size={40} className="mb-4 text-white" />,
      items: ["Onboarding new Africans", "Philanthropy / CSR"],
    },
    {
      title: "Intellectual Mobilization",
      icon: <FaBrain size={40} className="mb-4 text-white" />,
      items: [
        "Think tank & advocacy on Africa-Sweden issues",
        "Diversity consultancy",
      ],
    },
    {
      title: "Networking",
      icon: <FaNetworkWired size={40} className="mb-4 text-white" />,
      items: [
        "Inspirational talk shows",
        "After work mingles",
        "Cultural events",
        "Group trips",
      ],
    },
  ];

  return (
    <section id="about" className="pb-4 text-center bg-gray-100">
      <div className="container mx-auto">
        <div className="relative mb-8 ">
          <Carousel
            showArrows={false}
            showThumbs={false}
            showStatus={false}
            infiniteLoop
            autoPlay
            interval={3000}
            className="h-[400px] w-full"
          >
            {carouselImages.map((image, index) => (
              <div key={index}>
                <img
                  loading="lazy"
                  src={image}
                  alt={`carousel-${index}`}
                  className="h-[400px] object-cover w-full"
                />
              </div>
            ))}
          </Carousel>
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <h2 className="text-4xl font-bold text-white">TOGETHER WE CAN</h2>
          </div>
        </div>

        <div className="mb-8 w-[90%] mx-auto  ">
          <h3 className="text-2xl font-semibold my-10">What Our Mission Is:</h3>
          <p className="text-lg mb-4">
            African Professionals Network (APN), Sweden launched in October 2019
            as a platform for connecting and empowering Africans from all
            professional and cultural backgrounds to make an impact in their
            communities.
          </p>
          <p className="text-lg mb-4">
            We welcome members, collaborators, and partners who are passionate
            about improving the quality of the professional experience for
            Africans and people of African descent in Sweden. Members are
            welcome from all across Sweden, while we're actively looking to
            engage with like minds across the Nordics.
          </p>
          <p className="text-lg mb-4">
            We engage and enrich the experience of our members via workshops,
            trainings, programs, networking events, and providing real
            opportunities for partnership. Long term, we aim to be the focal
            point for engagement with business, governmental, and
            non-governmental stakeholders around issues affecting the African
            professional in Sweden.
          </p>
        </div>

        <div className="mb-8 bg-white text-gray-800 py-10">
          <h3 className="text-2xl font-semibold my-10">
            Our Objectives as community
          </h3>
          <div className="w-[90%] mx-auto grid grid-cols-1 lg:grid-cols-2  gap-8">
            {objectives.map((objective, index) => (
              <div
                key={index}
                className="bg-gradient-to-r from-orange-500 to-blue-500 p-6 rounded-lg shadow-lg transform transition duration-300 hover:scale-105"
              >
                <div className="text-center text-white">
                  <div className="flex justify-center items-center  rounded-full w-16 h-16 mb-4 mx-auto">
                    {objective.icon}
                  </div>
                  <h4 className="text-xl font-semibold mb-4">
                    {objective.title}
                  </h4>
                  <ul className="list-disc list-inside  text-left custom-list">
                    {objective.items.map((item, idx) => (
                      <li key={idx} className="mb-2">
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="mb-8 w-[90%] mx-auto">
          <h3 className="text-2xl font-semibold my-10">Meet Our Team</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                name: "Ayowale Obineye",
                role: "Events & programs manager",
                linkedIn: "https://www.linkedin.com/in/ayowaleobineye",
                img: "/images/ayowale.webp",
              },
              {
                name: "Margaret Githinji",
                role: "Partnership manager",
                linkedIn: "https://www.linkedin.com/in/margaretgithinji",
                img: "/images/Margaret.webp",
              },
              {
                name: "Adeniyi Adeyemi",
                role: "President",
                linkedIn: "https://www.linkedin.com/in/adeniyi-adeyemi",
                img: "/images/Adeniyi.webp",
              },
              {
                name: "Kubrom Mulugheta",
                role: "Community manager",
                linkedIn:
                  "https://www.linkedin.com/in/kubrom-mulugheta-14b5b5113",
                img: "/images/kubrema.jpg",
              },
              {
                name: "Irene Nalubega",
                role: "Finance manager",
                linkedIn: "https://www.linkedin.com/in/irenenalubega",
                img: "/images/Irene.webp",
              },
              {
                name: "Francis Awagu",
                role: "Mentorship program lead",
                linkedIn: "https://www.linkedin.com/in/francisawagu",
                img: "/images/francisawagu1.jpg",
              },
              {
                name: "Anne Njeri",
                role: "Social media & communications",
                linkedIn: "https://www.linkedin.com/in/anne-mundati",
                img: "/images/anne1.jpg",
              },
              {
                name: "Paul Opara John",
                role: "ProTalk series lead",
                linkedIn: "https://www.linkedin.com/in/ceopauljohn",
                img: "/images/Paul.webp",
              },
              {
                name: "Olagunju Oladele Emmanuel",
                role: "Web developer",
                linkedIn: "https://www.linkedin.com/in/mlo-olagunju",
                img: "/images/mlo_olagunju_oladele_emmanuel_software_engineer_fullstack_developer.jpg",
              },
            ].map((member, index) => (
              <a
                href={member.linkedIn}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500"
              >
                <div
                  key={index}
                  className="bg-white p-6 mx-auto rounded-lg h-full transform transition duration-300 hover:shadow-sm shadow-2xl hover:scale-105"
                >
                  <img
                    loading="lazy"
                    src={member.img}
                    alt={`A portrait picture of ${member.name}`}
                    className=" rounded"
                  />
                  <div className="flex items-center justify-center gap-2 my-2">
                    <FaLinkedin className="text-[30px]" />
                    <h4 className="text-xl font-semibold  capitalize flex justify-center">
                      {member.name}
                    </h4>
                  </div>
                  <p className="text-md mb-2 text-gray-700 capitalize">
                    {member.role}
                  </p>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
