import React, { useState } from "react";
import axios from "axios";
import { FaInstagram, FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://apnbackend.onrender.com/api/newsletter", {
        email,
      });
      toast.success("Thank you for subscribing!");
      setEmail("");
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Failed to subscribe. Please try again.");
      }
    }
  };

  const socialMediaLinks = [
    {
      icon: <FaInstagram size={30} className="cursor-pointer" />,
      url: "https://www.instagram.com/africanprofessionalssweden",
    },
    {
      icon: <FaFacebook size={30} className="cursor-pointer" />,
      url: "https://facebook.com/APNSweden",
    },
    {
      icon: <FaTwitter size={30} className="cursor-pointer" />,
      url: "https://twitter.com/apnsweden",
    },
    {
      icon: <FaLinkedin size={30} className="cursor-pointer" />,
      url: "https://www.linkedin.com/company/apnsweden/mycompany",
    },
  ];

  return (
    <section id="contact" className="py-4 bg-white text-center">
      <div className="container w-[90%] mx-auto flex flex-col">
        <div>
          <h2 className="text-4xl font-bold mb-4">Contact Us</h2>
          <address className="text-lg mb-8">
            <a
              href="https://maps.app.goo.gl/LwjigrUca5VEkaWj6"
              target="_blank"
              rel="noopener noreferrer"
            >
              Vilstigen 4, 192 32 Sollentuna, STOCKHOLM
            </a>
            <br />
            <a href="mailto:africanpronetwork@gmail.com">
              africanpronetwork@gmail.com
            </a>
          </address>

          <div className="flex justify-center space-x-4 mb-8">
            {socialMediaLinks.map((social, index) => (
              <a
                key={index}
                href={social.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {social.icon}
              </a>
            ))}
          </div>
        </div>

        <div className="mb-8 flex items-center flex-col mx-auto">
          <p className="text-lg">Donate</p>
          <p className="mb-2">Swish Number: 1233099173</p>
          <p>QR Code:</p>
          <img loading="lazy" src="/images/donate.webp" alt="Donate QR Code" />
        </div>

        <div>
          <form
            onSubmit={handleSubmit}
            className="max-w-lg mx-auto bg-white rounded-lg shadow-lg p-8 mb-8"
          >
            <h3 className="text-2xl font-bold mb-6">
              Subscribe to our newsletter
            </h3>
            <div className="mb-4">
              <label htmlFor="email" className="block mb-2 font-semibold">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full py-2 px-4 rounded bg-gray-100 focus:outline-none focus:bg-white"
                placeholder="Enter your email"
                required
              />
            </div>
            <button
              type="submit"
              className="bg-[#f46524] text-white font-bold py-3 px-6 rounded hover:bg-[#e55524] transition-colors duration-300"
            >
              Subscribe
            </button>
          </form>
          <ToastContainer />
          <p className="text-lg mb-8">
            For any inquiries or more information, please contact us via email
            or follow us on social media.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Contact;
