import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import AdminLogin from "./AdminLogin";
import AdminDashboard from "./AdminDashboard";

import ViewEventRegistrations from "./admins-events/ViewEventRegistrations";
import ViewUsers from "./admins-events/ViewUsers ";
import PostEvent from "./admins-events/PostEvent ";
import PostProgram from "./admins-events/PostProgram";
import ManagePrograms from "./admins-events/ManagePrograms";
import EditProgram from "./admins-events/EditProgram.jsxEditProgram";
import MentorMenteeApplications from "./admins-events/MentorMenteeApplications ";
import AdminNewsletter from "./admins-events/AdminNewsletter";
import DownloadGuideList from "./admins-events/DownloadGuideList";

const AdminPage = () => {
  const [token, setToken] = useState(localStorage.getItem("adminToken"));
  const navigate = useNavigate();

  const handleLogin = (token) => {
    setToken(token);
    localStorage.setItem("adminToken", token);
    navigate("/apn/admin/dashboard");
    window.location.reload();
  };

  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem("adminToken");
    navigate("/apn/admin");
    window.location.reload();
  };

  useEffect(() => {
    // Optional: Implement token validation logic here
  }, [token]);

  if (!token) {
    return <AdminLogin onLogin={handleLogin} />;
  }

  return (
    <div>
      <Routes>
        <Route
          path="/dashboard"
          element={<AdminDashboard onLogout={handleLogout} />}
        />
        <Route path="/view-users" element={<ViewUsers />} />
        <Route path="/post-event" element={<PostEvent />} />
        <Route path="/post-program" element={<PostProgram />} />
        <Route path="/manage-programs" element={<ManagePrograms />} />
        <Route path="/edit-program/:id" element={<EditProgram />} />
        <Route path="/newsletter-subscriptions" element={<AdminNewsletter />} />
        <Route
          path="/welcome-to-sweden-guide-download-list"
          element={<DownloadGuideList />}
        />
        <Route
          path="/mentor-mentee-applications"
          element={<MentorMenteeApplications />}
        />
        <Route path="/" element={<Navigate to="dashboard" />} />
        <Route
          path="/events/:eventId/registrations"
          element={<ViewEventRegistrations />}
        />
      </Routes>
    </div>
  );
};

export default AdminPage;
