import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

const EditEvent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [registrationStartDate, setRegistrationStartDate] = useState("");
  const [registrationEndDate, setRegistrationEndDate] = useState("");
  const [banner, setBanner] = useState(null);
  const [customQuestions, setCustomQuestions] = useState([]);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await fetch(
          `https://apnbackend.onrender.com/api/events/${id}`
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        setEvent(data);
        setTitle(data.title);
        setDescription(data.description);
        setDate(data.date.split("T")[0]);
        setRegistrationStartDate(data.registrationStartDate.split("T")[0]);
        setRegistrationEndDate(data.registrationEndDate.split("T")[0]);
        setCustomQuestions(data.customQuestions || []);
      } catch (error) {
        console.error("Error fetching event:", error);
      }
    };

    fetchEvent();
  }, [id]);

  const addQuestion = () => {
    setCustomQuestions([
      ...customQuestions,
      { questionText: "", questionType: "text", options: [], required: false },
    ]);
  };

  const updateQuestion = (index, field, value) => {
    const updatedQuestions = [...customQuestions];
    updatedQuestions[index][field] = value;
    setCustomQuestions(updatedQuestions);
  };

  const addOption = (questionIndex) => {
    const updatedQuestions = [...customQuestions];
    updatedQuestions[questionIndex].options.push("");
    setCustomQuestions(updatedQuestions);
  };

  const updateOption = (questionIndex, optionIndex, value) => {
    const updatedQuestions = [...customQuestions];
    updatedQuestions[questionIndex].options[optionIndex] = value;
    setCustomQuestions(updatedQuestions);
  };

  const removeQuestion = (index) => {
    const updatedQuestions = customQuestions.filter((_, i) => i !== index);
    setCustomQuestions(updatedQuestions);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("date", date);
    formData.append("registrationStartDate", registrationStartDate);
    formData.append("registrationEndDate", registrationEndDate);
    formData.append("customQuestions", JSON.stringify(customQuestions));
    if (banner) {
      formData.append("banner", banner);
    }

    try {
      const response = await fetch(
        `https://apnbackend.onrender.com/api/events/${id}`,
        {
          method: "PUT",
          body: formData,
        }
      );

      if (response.ok) {
        navigate("/apn/admin/manage-events");
      } else {
        const errorData = await response.json();
        console.error("Failed to update event:", errorData);
      }
    } catch (error) {
      console.error("Error updating event:", error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center py-10">
      <div className="w-full max-w-4xl p-8 bg-white shadow-lg rounded-lg">
        <h2 className="text-2xl font-bold text-gray-800 mb-6">Edit Event</h2>
        {event && (
          <form onSubmit={handleUpdate}>
            <div className="mb-4">
              <label className="block text-gray-700">Title</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Description</label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
              ></textarea>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Date</label>
              <input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">
                Registration Start Date
              </label>
              <input
                type="date"
                value={registrationStartDate}
                onChange={(e) => setRegistrationStartDate(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">
                Registration End Date
              </label>
              <input
                type="date"
                value={registrationEndDate}
                onChange={(e) => setRegistrationEndDate(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Banner</label>
              <input
                type="file"
                onChange={(e) => setBanner(e.target.files[0])}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2">
                Custom Questions
              </label>
              {customQuestions.map((question, index) => (
                <div key={index} className="mb-4 p-4 border rounded">
                  <input
                    type="text"
                    value={question.questionText}
                    onChange={(e) =>
                      updateQuestion(index, "questionText", e.target.value)
                    }
                    placeholder="Question text"
                    className="w-full px-4 py-2 mb-2 border rounded-lg"
                  />
                  <select
                    value={question.questionType}
                    onChange={(e) =>
                      updateQuestion(index, "questionType", e.target.value)
                    }
                    className="w-full px-4 py-2 mb-2 border rounded-lg"
                  >
                    <option value="text">Text</option>
                    <option value="multipleChoice">Multiple Choice</option>
                    <option value="checkbox">Checkbox</option>
                  </select>
                  {(question.questionType === "multipleChoice" ||
                    question.questionType === "checkbox") && (
                    <div>
                      {question.options.map((option, optionIndex) => (
                        <input
                          key={optionIndex}
                          type="text"
                          value={option}
                          onChange={(e) =>
                            updateOption(index, optionIndex, e.target.value)
                          }
                          placeholder={`Option ${optionIndex + 1}`}
                          className="w-full px-4 py-2 mb-2 border rounded-lg"
                        />
                      ))}
                      <button
                        type="button"
                        onClick={() => addOption(index)}
                        className="bg-green-500 text-white px-4 py-2 rounded"
                      >
                        Add Option
                      </button>
                    </div>
                  )}
                  <label className="flex items-center mt-2">
                    <input
                      type="checkbox"
                      checked={question.required}
                      onChange={(e) =>
                        updateQuestion(index, "required", e.target.checked)
                      }
                      className="mr-2"
                    />
                    Required
                  </label>
                  <button
                    type="button"
                    onClick={() => removeQuestion(index)}
                    className="bg-red-500 text-white px-4 py-2 rounded mt-2"
                  >
                    Remove Question
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={addQuestion}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Add Question
              </button>
            </div>

            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-300"
            >
              Update Event
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default EditEvent;
