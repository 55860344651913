import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Confetti from "react-confetti";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import "react-toastify/dist/ReactToastify.css";

dayjs.extend(duration);

const RegisterEvent = () => {
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [answers, setAnswers] = useState({});
  const [showConfetti, setShowConfetti] = useState(false);
  const [timeLeft, setTimeLeft] = useState("");
  const [isRegistered, setIsRegistered] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await fetch(
          `https://apnbackend.onrender.com/api/events/${eventId}`
        );
        const data = await response.json();
        if (response.ok) {
          setEvent(data);
          calculateTimeLeft(data.registrationEndDate);
          initializeAnswers(data.customQuestions);
        } else {
          toast.error("Failed to fetch event details");
        }
      } catch (error) {
        toast.error("Error: " + error.message);
      }
    };

    fetchEvent();
  }, [eventId]);

  const initializeAnswers = (questions) => {
    const initialAnswers = {};
    questions.forEach((question) => {
      initialAnswers[question._id] =
        question.questionType === "checkbox" ? [] : "";
    });
    setAnswers(initialAnswers);
  };

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await fetch(
          `https://apnbackend.onrender.com/api/events/${eventId}`
        );
        const data = await response.json();
        if (response.ok) {
          setEvent(data);
          calculateTimeLeft(data.registrationEndDate);
        } else {
          toast.error("Failed to fetch event details");
        }
      } catch (error) {
        toast.error("Error: " + error.message);
      }
    };

    fetchEvent();
  }, [eventId]);

  useEffect(() => {
    if (event && event.registrationEndDate) {
      const timer = setInterval(() => {
        calculateTimeLeft(event.registrationEndDate);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [event]);

  const calculateTimeLeft = (endDate) => {
    const now = dayjs();
    const end = dayjs(endDate);
    const diff = end.diff(now);

    if (diff <= 0) {
      setTimeLeft("Registration has ended");
    } else {
      const duration = dayjs.duration(diff);
      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();
      setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    }
  };

  const checkRegistrationStatus = async () => {
    try {
      const response = await fetch(
        `https://apnbackend.onrender.com/api/events/isRegistered/${eventId}/${userEmail}`
      );
      const data = await response.json();
      if (data.isRegistered) {
        setIsRegistered(true);
        toast.info("You are already registered for this event");
      } else {
        setIsRegistered(false);
      }
    } catch (error) {
      toast.error("Error: " + error.message);
    }
  };

  useEffect(() => {
    if (userEmail) {
      checkRegistrationStatus();
    }
  }, [userEmail]);

  const handleAnswerChange = (questionId, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Format answers to match the expected structure
    const formattedAnswers = Object.entries(answers).map(
      ([questionId, answer]) => ({
        questionId,
        answer,
      })
    );

    const registration = {
      eventId,
      userName,
      userEmail,
      answers: formattedAnswers,
    };

    try {
      const response = await fetch(
        "https://apnbackend.onrender.com/api/events/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(registration),
        }
      );

      if (response.ok) {
        toast.success("Registration successful!");
        setShowConfetti(true);
        setTimeout(() => {
          navigate("/events");
        }, 3000); // Navigate after 3 seconds
      } else {
        const errorData = await response.json();
        toast.error(
          `Failed to register for the event: ${
            errorData.error || "Unknown error"
          }`
        );
      }
    } catch (error) {
      toast.error("Error: " + error.message);
    }
  };

  const renderQuestionInput = (question) => {
    switch (question.questionType) {
      case "text":
        return (
          <input
            type="text"
            value={answers[question._id] || ""}
            onChange={(e) => handleAnswerChange(question._id, e.target.value)}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
            required={question.required}
          />
        );
      case "multipleChoice":
        return (
          <select
            value={answers[question._id] || ""}
            onChange={(e) => handleAnswerChange(question._id, e.target.value)}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
            required={question.required}
          >
            <option value="">Select an option</option>
            {question.options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        );
      case "checkbox":
        return (
          <div>
            {question.options.map((option, index) => (
              <label key={index} className="block">
                <input
                  type="checkbox"
                  checked={answers[question._id]?.includes(option) || false}
                  onChange={(e) => {
                    const newAnswers = e.target.checked
                      ? [...(answers[question._id] || []), option]
                      : (answers[question._id] || []).filter(
                          (item) => item !== option
                        );
                    handleAnswerChange(question._id, newAnswers);
                  }}
                  className="mr-2"
                />
                {option}
              </label>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center py-10">
      <ToastContainer />
      {showConfetti && <Confetti recycle={false} numberOfPieces={500} />}
      <div className="w-full max-w-4xl p-8 bg-white shadow-lg rounded-lg">
        {event ? (
          <div className="mb-6 text-center">
            <h1 className="text-4xl font-bold text-gray-800 mb-2 text-wrap">
              Register for {event.title}
            </h1>
            <p className="text-gray-700 mb-2">
              on {dayjs(event.date).format("MMMM D, YYYY")}
            </p>
            <div className="flex-col flex">
              <p className="text-red-600 font-semibold">
                Registration ends in:
              </p>
              <p className="text-red-600 font-semibold">{timeLeft}</p>
            </div>
          </div>
        ) : (
          <div className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-50 flex justify-center items-center">
            <p className="text-white">Loading event details...</p>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Your Name</label>
            <input
              type="text"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
              required
              disabled={isRegistered}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Your Email</label>
            <input
              type="email"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
              required
              disabled={isRegistered}
            />
          </div>
          {event &&
            event.customQuestions &&
            event.customQuestions.map((question, index) => (
              <div key={index} className="mb-4">
                <label className="block text-gray-700 mb-2">
                  {question.questionText}{" "}
                  {question.required && <span className="text-red-500">*</span>}
                </label>
                {renderQuestionInput(question)}
              </div>
            ))}
          <div className="flex justify-between items-center">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-300"
              disabled={isRegistered}
            >
              {isRegistered ? "Already Registered" : "Register"}
            </button>
            <button
              type="button"
              onClick={() => navigate("/events")}
              className="bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded transition duration-300"
            >
              Back to Events
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegisterEvent;
