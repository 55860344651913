import React from "react";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <main className="bg-gray-100">
      <section id="about" className="py-20 text-center mx-auto w-[90%]">
        <div className="container mx-auto">
          <h2 className="text-4xl font-bold mb-4">About Us</h2>
          <p className="text-lg mb-8">
            African Professionals Network (APN), Sweden launched in October 2019
            as a platform for connecting and empowering Africans from all
            professional and cultural backgrounds to make an impact in their
            communities.
          </p>
          <div className="grid md:grid-cols-2 gap-2 mb-8">
            <img
              loading="lazy"
              src="/images/manstandinfront.jpg"
              alt="About Us"
              className="mx-auto rounded shadow-lg h-full w-full"
            />
            <img
              loading="lazy"
              src="/images/audiences.jpg"
              alt="About Us"
              className="mx-auto rounded shadow-lg h-full w-full"
            />
          </div>

          <Link
            to={"/about"}
            className="bg-orange-500 rounded-lg py-2 px-4 text-white"
          >
            Learn More...
          </Link>
        </div>
      </section>
    </main>
  );
};

export default AboutUs;
