import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const PostEvent = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [registrationStartDate, setRegistrationStartDate] = useState("");
  const [registrationEndDate, setRegistrationEndDate] = useState("");
  const [banner, setBanner] = useState(null);
  const [customQuestions, setCustomQuestions] = useState([]);
  const navigate = useNavigate();

  const addQuestion = () => {
    setCustomQuestions([
      ...customQuestions,
      { questionText: "", questionType: "text", options: [], required: false },
    ]);
  };

  const updateQuestion = (index, field, value) => {
    const updatedQuestions = [...customQuestions];
    updatedQuestions[index][field] = value;
    setCustomQuestions(updatedQuestions);
  };

  const addOption = (questionIndex) => {
    const updatedQuestions = [...customQuestions];
    updatedQuestions[questionIndex].options.push("");
    setCustomQuestions(updatedQuestions);
  };

  const updateOption = (questionIndex, optionIndex, value) => {
    const updatedQuestions = [...customQuestions];
    updatedQuestions[questionIndex].options[optionIndex] = value;
    setCustomQuestions(updatedQuestions);
  };

  const removeQuestion = (index) => {
    const updatedQuestions = customQuestions.filter((_, i) => i !== index);
    setCustomQuestions(updatedQuestions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("date", date);
    formData.append("registrationStartDate", registrationStartDate);
    formData.append("registrationEndDate", registrationEndDate);
    formData.append("customQuestions", JSON.stringify(customQuestions));
    if (banner) {
      formData.append("banner", banner);
    }

    try {
      const response = await fetch(
        "https://apnbackend.onrender.com/api/events",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        navigate("/apn/admin/dashboard");
      } else {
        const errorData = await response.json();
        console.error("Failed to post event:", errorData);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center py-10">
      <div className="w-full max-w-4xl p-8 bg-white shadow-lg rounded-lg">
        <h2 className="text-2xl font-bold text-gray-800 mb-6">Post an Event</h2>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Event Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">
              Event Description
            </label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
              required
            ></textarea>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Event Date</label>
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">
              Registration Start Date
            </label>
            <input
              type="date"
              value={registrationStartDate}
              onChange={(e) => setRegistrationStartDate(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">
              Registration End Date
            </label>
            <input
              type="date"
              value={registrationEndDate}
              onChange={(e) => setRegistrationEndDate(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Event Banner</label>
            <input
              type="file"
              onChange={(e) => setBanner(e.target.files[0])}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
            />
          </div>

          {/*  */}

          {/*  */}
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Custom Questions</label>
            {customQuestions.map((question, index) => (
              <div key={index} className="mb-4 p-4 border rounded">
                <input
                  type="text"
                  value={question.questionText}
                  onChange={(e) =>
                    updateQuestion(index, "questionText", e.target.value)
                  }
                  placeholder="Question text"
                  className="w-full px-4 py-2 mb-2 border rounded-lg"
                />
                <select
                  value={question.questionType}
                  onChange={(e) =>
                    updateQuestion(index, "questionType", e.target.value)
                  }
                  className="w-full px-4 py-2 mb-2 border rounded-lg"
                >
                  <option value="text">Text</option>
                  <option value="multipleChoice">Multiple Choice</option>
                  <option value="checkbox">Checkbox</option>
                </select>
                {(question.questionType === "multipleChoice" ||
                  question.questionType === "checkbox") && (
                  <div>
                    {question.options.map((option, optionIndex) => (
                      <input
                        key={optionIndex}
                        type="text"
                        value={option}
                        onChange={(e) =>
                          updateOption(index, optionIndex, e.target.value)
                        }
                        placeholder={`Option ${optionIndex + 1}`}
                        className="w-full px-4 py-2 mb-2 border rounded-lg"
                      />
                    ))}
                    <button
                      type="button"
                      onClick={() => addOption(index)}
                      className="bg-green-500 text-white px-4 py-2 rounded"
                    >
                      Add Option
                    </button>
                  </div>
                )}
                <label className="flex items-center mt-2">
                  <input
                    type="checkbox"
                    checked={question.required}
                    onChange={(e) =>
                      updateQuestion(index, "required", e.target.checked)
                    }
                    className="mr-2"
                  />
                  Required
                </label>
                <button
                  type="button"
                  onClick={() => removeQuestion(index)}
                  className="bg-red-500 text-white px-4 py-2 rounded mt-2"
                >
                  Remove Question
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={addQuestion}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Add Question
            </button>
          </div>

          <div className="flex justify-between items-center">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-300"
            >
              Post Event
            </button>
            <button
              type="button"
              onClick={() => navigate("/apn/admin/dashboard")}
              className="bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded transition duration-300"
            >
              Back to Dashboard
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PostEvent;
