import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const MentorMenteeApplications = () => {
  const [applications, setApplications] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const response = await axios.get(
          "https://apnbackend.onrender.com/api/applications"
        );
        setApplications(response.data);
      } catch (err) {
        console.error("Error fetching applications", err);
      }
    };

    fetchApplications();
  }, []);

  const exportPDF = () => {
    const doc = new jsPDF();
    const tableColumn = [
      "Name",
      "Email",
      "Phone",
      "Role",
      "Experience",
      "Goals",
    ];
    const tableRows = [];

    applications.forEach((application) => {
      const applicationData = [
        application.name,
        application.email,
        application.phone,
        application.role,
        application.experience,
        application.goals,
      ];
      tableRows.push(applicationData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
    });

    doc.text(`Mentor/Mentee Applications`, 14, 15);
    doc.save(`mentor_mentee_applications.pdf`);
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center py-10">
      <div className="w-full max-w-4xl p-8 bg-white shadow-lg rounded-lg">
        <div className="flex items-center justify-between">
          <h2 className="text-2xl font-bold mb-6">
            Mentor/Mentee Applications
          </h2>
          <button
            type="button"
            onClick={() => navigate("/apn/admin/dashboard")}
            className="bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded transition duration-300"
          >
            Back to Dashboard
          </button>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">Name</th>
                <th className="py-2 px-4 border-b">Email</th>
                <th className="py-2 px-4 border-b">Phone</th>
                <th className="py-2 px-4 border-b">Role</th>
                <th className="py-2 px-4 border-b">Experience</th>
                <th className="py-2 px-4 border-b">Goals</th>
              </tr>
            </thead>
            <tbody>
              {applications.map((application) => (
                <tr key={application.id}>
                  <td className="py-2 px-4 border-b">{application.name}</td>
                  <td className="py-2 px-4 border-b">{application.email}</td>
                  <td className="py-2 px-4 border-b">{application.phone}</td>
                  <td className="py-2 px-4 border-b">{application.role}</td>
                  <td className="py-2 px-4 border-b">
                    {application.experience}
                  </td>
                  <td className="py-2 px-4 border-b">{application.goals}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button
          onClick={exportPDF}
          className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-300"
        >
          Export to PDF
        </button>
      </div>
    </div>
  );
};

export default MentorMenteeApplications;
