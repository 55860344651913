import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const ViewEventRegistrations = () => {
  const [events, setEvents] = useState([]);
  const [registrations, setRegistrations] = useState({});
  const [expandedUsers, setExpandedUsers] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(
          "https://apnbackend.onrender.com/api/events"
        );
        const data = await response.json();
        setEvents(data);

        const registrationsData = {};
        await Promise.all(
          data.map(async (event) => {
            const res = await fetch(
              `https://apnbackend.onrender.com/api/events/${event._id}/registrations`
            );
            const regData = await res.json();
            registrationsData[event._id] = regData;
          })
        );
        setRegistrations(registrationsData);
      } catch (error) {
        console.error("Error fetching events or registrations:", error);
      }
    };

    fetchEvents();
  }, []);

  const exportPDF = (eventId) => {
    const doc = new jsPDF();
    const event = events.find((event) => event._id === eventId);
    const tableColumn = [
      "User Name",
      "Email",
      "Registration Date",
      ...event.customQuestions.map((q) => q.questionText),
    ];
    const tableRows = [];

    registrations[eventId].forEach((user) => {
      const userData = [
        user.userName,
        user.userEmail,
        new Date(user.registrationDate).toLocaleString(),
        ...event.customQuestions.map((question) => {
          const answer = user.answers.find(
            (a) => a.questionId === question._id
          );
          return answer
            ? Array.isArray(answer.answer)
              ? answer.answer.join(", ")
              : answer.answer
            : "N/A";
        }),
      ];
      tableRows.push(userData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
    });

    doc.text(`Registrations for ${event.title}`, 14, 15);
    doc.save(`registrations_${event.title}.pdf`);
  };

  const goBackToDashboard = () => {
    navigate("/apn/admin/dashboard");
  };

  const toggleUserExpansion = (eventId, userId) => {
    setExpandedUsers((prev) => ({
      ...prev,
      [eventId]: {
        ...prev[eventId],
        [userId]: !prev[eventId]?.[userId],
      },
    }));
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center py-10">
      <div className="w-full max-w-6xl p-8 bg-white shadow-lg rounded-lg">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-800">
            Event Registrations
          </h2>
          <button
            onClick={goBackToDashboard}
            className="bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded transition duration-300"
          >
            Back to Dashboard
          </button>
        </div>
        {events.map((event) => (
          <div key={event._id} className="mb-8">
            <h3 className="text-xl font-semibold mb-2">{event.title}</h3>
            <div className="overflow-x-auto mb-4">
              <table className="min-w-full bg-white">
                <thead>
                  <tr>
                    <th className="py-2 px-4 border-b text-start">User Name</th>
                    <th className="py-2 px-4 border-b text-start">Email</th>
                    <th className="py-2 px-4 border-b text-start">
                      Registration Date
                    </th>
                    <th className="py-2 px-4 border-b text-start">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {registrations[event._id] &&
                  registrations[event._id].length > 0 ? (
                    registrations[event._id].map((user) => (
                      <React.Fragment key={user._id}>
                        <tr>
                          <td className="py-2 px-4 border-b">
                            {user.userName}
                          </td>
                          <td className="py-2 px-4 border-b">
                            {user.userEmail}
                          </td>
                          <td className="py-2 px-4 border-b">
                            {new Date(user.registrationDate).toLocaleString()}
                          </td>
                          <td className="py-2 px-4 border-b">
                            <button
                              onClick={() =>
                                toggleUserExpansion(event._id, user._id)
                              }
                              className="text-blue-500 hover:text-blue-700"
                            >
                              {expandedUsers[event._id]?.[user._id]
                                ? "Hide Details"
                                : "Show Details"}
                            </button>
                          </td>
                        </tr>
                        {expandedUsers[event._id]?.[user._id] && (
                          <tr>
                            <td colSpan="4" className="py-2 px-4 border-b">
                              <div className="bg-gray-100 p-4 rounded">
                                <h4 className="font-semibold mb-2">
                                  Custom Questions:
                                </h4>
                                {event.customQuestions.map((question) => {
                                  const answer = user.answers.find(
                                    (a) => a.questionId === question._id
                                  );
                                  return (
                                    <div key={question._id} className="mb-2">
                                      <p className="font-medium">
                                        {question.questionText}
                                      </p>
                                      <p>
                                        {answer
                                          ? Array.isArray(answer.answer)
                                            ? answer.answer.join(", ")
                                            : answer.answer
                                          : "N/A"}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="4"
                        className="py-2 px-4 border-b text-center"
                      >
                        No registrations yet.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {registrations[event._id] &&
              registrations[event._id].length > 0 && (
                <button
                  onClick={() => exportPDF(event._id)}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-300"
                >
                  Export to PDF
                </button>
              )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ViewEventRegistrations;
