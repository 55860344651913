import React, { useEffect, useState } from "react";
import axios from "axios";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useNavigate } from "react-router-dom";

const AdminNewsletter = () => {
  const [subscribers, setSubscribers] = useState([]);

  useEffect(() => {
    const fetchSubscribers = async () => {
      try {
        const response = await axios.get(
          "https://apnbackend.onrender.com/api/newsletter"
        );
        setSubscribers(response.data);
      } catch (error) {
        console.error("Error fetching subscribers:", error);
      }
    };

    fetchSubscribers();
  }, []);

  const downloadPDF = () => {
    const input = document.getElementById("subscribers-table");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("subscribers.pdf");
    });
  };
  const navigate = useNavigate();

  const goBackToDashboard = () => {
    navigate("/apn/admin/dashboard");
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center py-10">
      <div className="w-full max-w-4xl p-8 bg-white shadow-lg rounded-lg">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-800">
            Newsletter Subscribers
          </h2>
          <button
            onClick={goBackToDashboard}
            className="bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded transition duration-300"
          >
            Back to Dashboard
          </button>
        </div>
        <table
          id="subscribers-table"
          className="w-full border-collapse border border-gray-300 mb-4"
        >
          <thead>
            <tr>
              <th className="border border-gray-300 p-2">Email</th>
              <th className="border border-gray-300 p-2">Date Subscribed</th>
            </tr>
          </thead>
          <tbody>
            {subscribers.map((subscriber, index) => (
              <tr key={index}>
                <td className="border border-gray-300 p-2">
                  {subscriber.email}
                </td>
                <td className="border border-gray-300 p-2">
                  {new Date(subscriber.dateSubscribed).toLocaleDateString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button
          onClick={downloadPDF}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300"
        >
          Download PDF
        </button>
      </div>
    </div>
  );
};

export default AdminNewsletter;
