import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import JoinPopup from "../components/JoinPopup";
import Program from "../components/programPage/Program";

const ProgramPage = () => {
  const [showJoinPopup, setShowJoinPopup] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleJoinClick = () => {
    setShowJoinPopup(true);
  };

  const handleClosePopup = () => {
    setShowJoinPopup(false);
  };
  return (
    <>
      <Header onJoinClick={handleJoinClick} />
      <Program />
      <Contact />
      <Footer />
      <JoinPopup show={showJoinPopup} onClose={handleClosePopup} />
    </>
  );
};

export default ProgramPage;
