import React, { useState, useRef, forwardRef } from "react";
import ApplicationModal from "../../../components/ApplicationModal ";

const FAQSection = ({ section, activeIndex, toggleAccordion }) => (
  <div className="mb-4">
    <h3 className="text-2xl font-semibold mt-10 mb-3">{section.title}</h3>
    {section.questions.map((faq, index) => (
      <div key={index} className="mb-4">
        <button
          onClick={() => toggleAccordion(`${section.title}-${index}`)}
          className="text-left w-full text-2xl font-semibold text-[#4C469D] focus:outline-none focus:ring-2 focus:ring-[#4C469D] focus:ring-opacity-50"
        >
          <span className="flex justify-between items-center">
            {faq.question}
            <span>
              {activeIndex === `${section.title}-${index}` ? "-" : "+"}
            </span>
          </span>
        </button>
        <div
          className={`overflow-hidden transition-all duration-300 ${
            activeIndex === `${section.title}-${index}`
              ? "max-h-screen"
              : "max-h-0"
          }`}
        >
          <p className="mt-2 ml-2 text-xl">{faq.answer}</p>
        </div>
      </div>
    ))}
  </div>
);

// Modify the MentorshipCard component to use React.forwardRef
const MentorshipCard = forwardRef(
  (
    {
      image,
      title,
      roleTitle,
      roleDesc,
      impactTitle,
      impactDesc,
      requirementsTitle,
      requirements,
      buttonText,
      isInverted,
      onApplyClick,
    },
    ref
  ) => (
    <div ref={ref}>
      <div
        className={`lg:w-[90%] mx-2  md:w-[60%] p-4 md:p-6 shadow-lg rounded-lg text-white bg-[#194569] flex lg:flex-row flex-col gap-8 items-center md:mx-auto ${
          isInverted && "flex-row-reverse bg-[#194569] text-white"
        }`}
      >
        <img
          loading="lazy"
          src={image}
          alt={title}
          className="mb-4 rounded-md h-[450px]"
        />
        <div className="text-start flex flex-col items-start">
          <h2 className="text-3xl font-bold mb-4">{title}</h2>
          <h3 className="text-2xl font-semibold mb-2">{roleTitle}</h3>
          <p className="mb-4">{roleDesc}</p>
          <h3 className="text-2xl font-semibold mb-2">{impactTitle}</h3>
          <p className="mb-4">{impactDesc}</p>
          <h3 className="text-2xl font-semibold mb-2">{requirementsTitle}</h3>
          <ul className="list-disc list-inside mb-4">
            {requirements.map((req, index) => (
              <li key={index}>{req}</li>
            ))}
          </ul>
          <button
            onClick={onApplyClick}
            className={`py-2 px-4 ${
              isInverted && "flex-row-reverse  bg-[#fff] text-[#194569] "
            } rounded-lg  bg-[#fff] text-[#194569]  transition-transform transform hover:scale-105`}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  )
);

const Content = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [role, setRole] = useState("");
  const mentorRef = useRef(null);
  const menteeRef = useRef(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const scrollToMentor = () => {
    if (mentorRef.current) {
      mentorRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToMentee = () => {
    if (menteeRef.current) {
      menteeRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const openModal = (role) => {
    setRole(role);
    setIsModalOpen(true);
  };

  const faqSections = [
    {
      title: "General Mentorship Questions",
      questions: [
        {
          question: "What is the APN Sweden Mentorship Program?",
          answer:
            "The APN Sweden Mentorship Program is an initiative designed to empower professionals of African descent in Sweden through mentorship, aiming to foster career growth, provide networking opportunities, and help integrate participants into Swedish society.",
        },
        {
          question: "Who can apply to be a mentor or a mentee?",
          answer:
            "Professionals of African descent residing in Sweden can apply to be mentees. Mentors can be individuals of any background who have a successful track record in their field and are currently engaged in the Swedish workforce.",
        },
        {
          question: "How are mentors and mentees matched?",
          answer:
            "Matches are made based on shared professional interests, career goals, industry experience, and the specific needs of the mentee. We strive to create pairs that will benefit most from each other's knowledge and experience.",
        },
        {
          question: "How long does the mentorship program last?",
          answer:
            "The program runs for 6 months, with mentors and mentees meeting bi-weekly for at least 2 hours per meeting.",
        },
        {
          question: "Is there a cost to participate in the program?",
          answer:
            "The APN Sweden Mentorship Program is free for both mentors and mentees. Our goal is to provide a platform for growth without financial barriers.",
        },
        {
          question: "What are the expected outcomes of the mentorship program?",
          answer:
            "Mentees can expect to gain industry insights, develop job-seeking skills, network with professionals, and receive guidance on achieving career growth. Mentors can expect to develop coaching skills, give back to the community, and gain fresh perspectives from emerging professionals.",
        },
      ],
    },
    {
      title: "Commitment and Expectations",
      questions: [
        {
          question: "What is expected of me as a mentee?",
          answer:
            "Mentees are expected to be curious, actively participate, challenge their knowledge, aim high, be proactive and self-driven, ask questions, and be good listeners.",
        },
        {
          question: "What is expected of me as a mentor?",
          answer:
            "Mentors are expected to commit their time and knowledge, participate in all scheduled meetings, support their mentee's growth, and be willing to share their professional journey.",
        },
      ],
    },
    {
      title: "Meeting Structure",
      questions: [
        {
          question: "Will meetings be held in person or online?",
          answer:
            "Meetings can be held either in person or online, depending on the preferences and circumstances of the mentor-mentee pair. However, certain program events such as the start and closure will be held in person.",
        },
        {
          question: "Are there set agendas for mentorship meetings?",
          answer:
            "While there is a general framework provided to guide the mentorship, we encourage mentors and mentees to customize the agenda to best suit the mentee's goals.",
        },
      ],
    },
    {
      title: "Program Support",
      questions: [
        {
          question:
            "What kind of support does APN provide to mentors and mentees?",
          answer:
            "APN provides training materials, professional development resources, and support from the APN team throughout the program. We also host networking events and provide platforms for communication between program participants.",
        },
      ],
    },
    {
      title: "Application Process",
      questions: [
        {
          question: "How can I apply to the program?",
          answer:
            "You can apply through our website by filling out the application form for either mentors or mentees. Make sure to provide detailed information about your professional background and your goals for the mentorship.",
        },
        {
          question: "What is the deadline to apply for the upcoming cohort?",
          answer:
            "The application deadlines are announced prior to the start of each cohort. Please check our website or contact us directly for the most current information.",
        },
      ],
    },
    {
      title: "After the Program",
      questions: [
        {
          question: "What happens after the 6-month mentorship program ends?",
          answer:
            "We encourage mentors and mentees to maintain their professional relationship if mutually beneficial. Additionally, APN provides alumni resources and may feature successful pairings in our communications to inspire future participants.",
        },
      ],
    },
  ];

  return (
    <div className="container mx-auto pb-16 bg-gray-100">
      <section
        className="text-center mb-16 h-[50vh] lg:h-[70vh] text-white flex flex-col items-center justify-center gap-4"
        style={{
          backgroundImage: `url('/images/kinla.webp')`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="text-center mb-16">
          <h1 className="text-5xl font-bold mb-4">
            Empower Your Professional Journey in Sweden
          </h1>
          <p className="text-xl">
            Join the APN Mentorship Program and Unlock Your Potential
          </p>
        </div>
        <div className="flex gap-4">
          <button
            onClick={scrollToMentor}
            className="py-2 px-4 bg-[#4C469D] text-white rounded-lg transition-transform transform hover:scale-105"
          >
            Become a Mentor
          </button>
          <button
            onClick={scrollToMentee}
            className="py-2 px-4 bg-[#4C469D] text-white rounded-lg transition-transform transform hover:scale-105"
          >
            Become a Mentee
          </button>
        </div>
      </section>

      <section className="md:w-[70%] w-[90%] mx-auto py-16">
        <h1 className="text-5xl font-bold mb-4">About the Program</h1>
        <p className="text-xl">
          APN Sweden is dedicated to connecting and empowering professionals of
          African descent to make an impact in their communities by providing a
          platform for growth, networking, and integration into Swedish society.
          Our Mentorship Program is a strategic initiative aimed at bridging the
          gap between potential and opportunity, fostering career growth and
          developing future leaders.
        </p>
      </section>

      <div className="grid gap-8 mb-16 ">
        <MentorshipCard
          ref={mentorRef}
          image="/images/mentor.webp"
          title="Mentorship Program - Mentor"
          roleTitle="Role Overview"
          roleDesc="As a mentor, you will provide guidance, share your experiences, and support the professional growth of your mentee."
          impactTitle="Impact"
          impactDesc="By becoming a mentor, you will have the opportunity to make a lasting impact on someone's career and contribute to the success of the community."
          requirementsTitle="Requirements"
          requirements={[
            "Minimum of 5 years of professional experience.",
            "Commitment to meet with your mentee regularly.",
            "Willingness to share knowledge and provide guidance.",
          ]}
          buttonText="Apply to be a Mentor"
          isInverted={false}
          onApplyClick={() => openModal("Mentor")}
        />

        <MentorshipCard
          ref={menteeRef}
          image="/images/mentee.webp"
          title="Mentorship Program - Mentee"
          roleTitle="Role Overview"
          roleDesc="As a mentee, you will receive guidance and support from an experienced professional to help you achieve your career goals."
          impactTitle="Impact"
          impactDesc="By becoming a mentee, you will gain valuable insights, expand your professional network, and accelerate your career growth."
          requirementsTitle="Requirements"
          requirements={[
            "Desire to grow professionally and personally.",
            "Commitment to meet with your mentor regularly.",
            "Willingness to learn and apply feedback.",
          ]}
          buttonText="Apply to be a Mentee"
          isInverted={true}
          onApplyClick={() => openModal("Mentee")}
        />
      </div>

      <section className="md:w-[70%] w-[90%] mx-auto py-16">
        <h2 className="text-4xl font-bold mb-8">Frequently Asked Questions</h2>
        {faqSections.map((section, index) => (
          <FAQSection
            key={index}
            section={section}
            activeIndex={activeIndex}
            toggleAccordion={toggleAccordion}
          />
        ))}
      </section>

      <ApplicationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        role={role}
      />
    </div>
  );
};

export default Content;
