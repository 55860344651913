import React from "react";
import { Link } from "react-router-dom";

const Hero = ({ onJoinClick }) => {
  return (
    <section
      className="hero bg-cover bg-center h-screen text-white flex items-center justify-center relative"
      style={{ backgroundImage: "url(/images/heroBgImage.jpg)" }}
    >
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative z-[0] text-center">
        <h1 className="text-6xl font-bold">The Future Starts Here</h1>
        <p className="text-xl mt-4">
          Welcome to the official website of the African Professionals Network,
          Sweden
        </p>
        <div className="mt-8 space-x-4 z-0">
          <button
            className="bg-orange-500 px-6 py-3 rounded"
            onClick={onJoinClick}
          >
            Join Us
          </button>
          <Link to={"/about"} className="bg-white text-black px-6 py-3 rounded">
            Learn More
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Hero;
