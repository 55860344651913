import React, { useState } from "react";
import Headroom from "react-headroom";
import { IoMdClose } from "react-icons/io";
import { IoMdMenu } from "react-icons/io";
import { NavLink } from "react-router-dom";

const Header = ({ onJoinClick }) => {
  const [openNav, setOpenNav] = useState(false);

  const handleOpenNav = () => {
    setOpenNav(!openNav);
  };

  return (
    <>
      {openNav && (
        <div
          className="z-[1] fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 lg:hidden"
          onClick={handleOpenNav}
        />
      )}
      <Headroom>
        <header className="bg-gray-800 text-white py-4 w-full relative">
          <div className="w-[90%] container mx-auto flex justify-between items-center">
            <a href="/">
              <img
                loading="lazy"
                className="h-[50px]"
                src="/favicon.png"
                alt="APN Sweden Logo"
              />
            </a>

            <button onClick={handleOpenNav} className="lg:hidden block">
              {!openNav ? (
                <IoMdMenu className="text-2xl mx-2" />
              ) : (
                <IoMdClose className="text-2xl mx-2" />
              )}
            </button>
            <nav
              className={`nav ${
                !openNav
                  ? "hidden lg:flex gap-4 items-center"
                  : "flex lg:static gap-4 lg:flex-row flex-col lg:items-center items-start justify-between absolute z-50 right-0 top-[70px] lg:p-0 p-5 lg:bg-transparent bg-gray-800 lg:w-fit w-[60%] transition-all"
              }`}
            >
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? "active-link" : "hover:border-b transition"
                }
                onClick={handleOpenNav}
              >
                Home
              </NavLink>
              <NavLink
                to="/about"
                className={({ isActive }) =>
                  isActive ? "active-link" : "hover:border-b transition"
                }
                onClick={handleOpenNav}
              >
                About
              </NavLink>
              <NavLink
                to="/membership"
                className={({ isActive }) =>
                  isActive ? "active-link" : "hover:border-b transition"
                }
                onClick={handleOpenNav}
              >
                Membership
              </NavLink>
              <NavLink
                to="/events"
                className={({ isActive }) =>
                  isActive ? "active-link" : "hover:border-b transition"
                }
                onClick={handleOpenNav}
              >
                Events
              </NavLink>
              <NavLink
                to="/programs"
                className={({ isActive }) =>
                  isActive ? "active-link" : "hover:border-b transition"
                }
                onClick={handleOpenNav}
              >
                Programs
              </NavLink>
              <a
                onClick={handleOpenNav}
                className="hover:border-b transition"
                href="#contact"
              >
                Contact Us
              </a>
              <button
                className="bg-orange-500 px-4 py-2 rounded"
                onClick={() => {
                  onJoinClick();
                  handleOpenNav();
                }}
              >
                Join Us
              </button>
            </nav>
          </div>
        </header>
      </Headroom>
    </>
  );
};

export default Header;
