import React, { useState } from "react";
import { Link } from "react-router-dom";

const Programs = () => {
  const maxLength = 100;

  const programs = [
    {
      title: "Mentorship",
      description:
        "Aligning individuals based on similar educational backgrounds to assist in achieving professional goals. This program aims to provide guidance, support, and resources to help mentees reach their full potential.",
      image: "/images/testmentorship.jpg",
    },
    {
      title: "Workshops",
      description:
        "Engaging workshops on topics of interest to members. These workshops are designed to provide valuable insights, practical skills, and knowledge that can be applied in professional and personal development.",
      image: "/images/testworkshop.jpg",
    },
    {
      title: "Networking",
      description:
        "Fostering networks between mentees and established professionals. Our networking events create opportunities for meaningful connections, collaborations, and professional growth.",
      image: "/images/testmingle.jpg",
    },
  ];

  return (
    <section className="py-20 bg-gray-100 text-center">
      <div className="container mx-auto w-[90%]">
        <h2 className="text-4xl font-bold mb-4">Our Programs</h2>
        <p className="text-lg mb-8">
          Our programs pair professionals of African background with experienced
          mentors in individual mentorship groups.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {programs.map((program, index) => (
            <ProgramItem key={index} program={program} maxLength={maxLength} />
          ))}
        </div>
        <div className="mt-8">
          <Link
            to="/programs"
            className="bg-[#f46524] text-white font-bold py-3 px-6 rounded"
          >
            View All Programs
          </Link>
        </div>
      </div>
    </section>
  );
};

const ProgramItem = ({ program, maxLength }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  const truncatedText =
    program.description.length > maxLength
      ? program.description.substring(0, maxLength) + "..."
      : program.description;

  return (
    <div className="bg-white rounded shadow-lg p-6 group h-fit">
      <h3 className="text-2xl font-bold mb-2">{program.title}</h3>
      <p className="mb-4">
        {isTruncated ? truncatedText : program.description}
      </p>
      <button onClick={toggleTruncate} className="text-blue-500 underline">
        {isTruncated ? "Read More" : "Read Less"}
      </button>
      <div className="relative mt-4">
        <img
          loading="lazy"
          src={program.image}
          alt={program.title}
          className="block w-full h-auto rounded-lg shadow-md"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center rounded">
          <Link
            to={"/programs"}
            className="bg-[#f46524] text-white font-bold py-2 px-4 rounded"
          >
            Learn More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Programs;
