import React, { useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getData } from "country-list";

const countryData = getData();

const JoinPopup = ({ show, onClose }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    country: "",
    gender: "",
    profession: "",
    message: "",
    agreeWhatsApp: false,
    agreeNewsletter: false,
    team: "welcome",
  });

  const [page, setPage] = useState(1);
  const [showInfo, setShowInfo] = useState(false);

  if (!show) return null;

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone: value,
    });
  };

  const validateForm = () => {
    console.log("Form Data:", formData);

    const {
      firstName,
      lastName,
      email,
      phone,
      country,
      gender,
      profession,
      team,
    } = formData;

    if (page === 1 && (!firstName || !lastName || !email)) {
      toast.error("Please fill in all required fields on this page");
      return false;
    }
    if (page === 2 && (!phone || !country || !gender)) {
      toast.error("Please fill in all required fields on this page");
      return false;
    }
    if (page === 3 && !profession) {
      toast.error("Please fill in all required fields on this page");
      return false;
    }
    if (page === 4 && !team) {
      toast.error("Please fill in all required fields on this page");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const response = await fetch(
        "https://apnbackend.onrender.com/api/users/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message || "User registered successfully");
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          country: "",
          gender: "",
          profession: "",
          message: "",
          agreeWhatsApp: false,
          agreeNewsletter: false,
          team: "welcome",
        });
        setPage(1);
        setTimeout(() => {
          onClose();
        }, 2000);
      } else {
        toast.error(result.error || "Failed to register user");
      }
    } catch (error) {
      toast.error("Error: " + error.message);
    }
  };

  const renderPageOne = () => (
    <>
      <input
        type="text"
        name="firstName"
        placeholder="First Name"
        className="w-full mb-4 p-2 border rounded"
        value={formData.firstName}
        onChange={handleChange}
      />
      <input
        type="text"
        name="lastName"
        placeholder="Last Name"
        className="w-full mb-4 p-2 border rounded"
        value={formData.lastName}
        onChange={handleChange}
      />
      <input
        type="email"
        name="email"
        placeholder="Email"
        className="w-full mb-4 p-2 border rounded"
        value={formData.email}
        onChange={handleChange}
      />
      <div className="flex justify-between">
        <button
          type="button"
          className="w-full bg-orange-500 text-white p-2 rounded"
          onClick={() => {
            if (validateForm()) setPage(2);
          }}
        >
          Next
        </button>
      </div>
    </>
  );

  const renderPageTwo = () => (
    <>
      <PhoneInput
        country={"us"}
        value={formData.phone}
        onChange={handlePhoneChange}
        className="w-full mb-4 p-2 border rounded"
        enableSearch={true}
      />
      <select
        name="country"
        className="w-full mb-4 p-2 border rounded cursor-pointer"
        value={formData.country}
        onChange={handleChange}
      >
        <option value="">Select Country of Origin</option>
        {countryData.map((country) => (
          <option key={country.code} value={country.name}>
            {country.name}
          </option>
        ))}
      </select>
      <select
        name="gender"
        className="w-full mb-4 p-2 border rounded cursor-pointer"
        value={formData.gender}
        onChange={handleChange}
      >
        <option value="">Select Gender</option>
        <option value="male">Male</option>
        <option value="female">Female</option>
        <option value="other">Other</option>
      </select>
      <div className="flex justify-between flex-col-reverse gap-2">
        <button
          type="button"
          className="w-full bg-gray-500 text-white p-2 rounded mb-2"
          onClick={() => setPage(1)}
        >
          Previous
        </button>
        <button
          type="button"
          className="w-full bg-orange-500 text-white p-2 rounded"
          onClick={() => {
            if (validateForm()) setPage(3);
          }}
        >
          Next
        </button>
      </div>
    </>
  );

  const renderPageThree = () => (
    <>
      <input
        type="text"
        name="profession"
        placeholder="Profession / Job Title"
        className="w-full mb-4 p-2 border rounded"
        value={formData.profession}
        onChange={handleChange}
      />
      <textarea
        name="message"
        placeholder="Leave us a message..."
        className="w-full mb-4 p-2 border rounded"
        value={formData.message}
        onChange={handleChange}
      ></textarea>

      <div className="flex justify-between flex-col-reverse gap-2">
        <button
          type="button"
          className="w-full bg-gray-500 text-white p-2 rounded mb-2"
          onClick={() => setPage(2)}
        >
          Previous
        </button>
        <button
          type="button"
          className="w-full bg-orange-500 text-white p-2 rounded"
          onClick={() => {
            if (validateForm()) setPage(4);
          }}
        >
          Next
        </button>
      </div>
    </>
  );

  const renderPageFour = () => (
    <>
      <select
        name="team"
        className="w-full mb-4 p-2 border rounded cursor-pointer"
        value={formData.team}
        onChange={handleChange}
      >
        <option value="Welcome to Sweden Team">Welcome to Sweden Team</option>
        <option value="Events and Programs Team">
          Events and Programs Team
        </option>
        <option value="Mentorship Team">Mentorship Team</option>
        <option value="Finance Team">Finance Team</option>
        <option value="ProTalk Series Team">ProTalk Series Team</option>
        <option value="Partnership Team">Partnership Team</option>
        <option value="Community Management Team">
          Community Management Team
        </option>
        <option value="No Team">No Team</option>
      </select>
      <div className="w-full mb-4 p-2 border rounded">
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            name="agreeWhatsApp"
            checked={formData.agreeWhatsApp}
            onChange={handleChange}
            className="form-checkbox"
          />
          <span className="ml-2">
            I agree to be added to and receive updates via the APN WhatsApp
            group
          </span>
        </label>
      </div>
      <div className="w-full mb-4 p-2 border rounded">
        <label className="inline-flex justify-between items-center">
          <input
            type="checkbox"
            name="agreeNewsletter"
            checked={formData.agreeNewsletter}
            onChange={handleChange}
            className="form-checkbox"
          />
          <span className="ml-2">Agree to receive the APN Newsletter</span>
        </label>
      </div>
      <div className="flex justify-between flex-col-reverse gap-2">
        <button
          type="button"
          className="w-full bg-gray-500 text-white p-2 rounded mb-2"
          onClick={() => setPage(3)}
        >
          Previous
        </button>
        <button
          type="button"
          className="w-full bg-orange-500 text-white p-2 rounded"
          onClick={() => {
            if (validateForm()) setPage(5);
          }}
        >
          Next
        </button>
      </div>
    </>
  );

  const renderPageFive = () => (
    <>
      <div className="flex flex-col gap-4 my-4">
        <h1 className="text-4xl font-bold">How to pay for your membership</h1>

        <p>
          Please use the same name in your registration as a reference when
          making the Swish payment.{" "}
          <span className="text-orange-500 font-semibold">
            Kindly be aware that the annual membership fee is 300 SEK/year for
            employed adults.
          </span>
        </p>

        <p className="text-orange-500 font-semibold">
          Students and unemployed individuals qualify for a 50% discount,
          reducing the fee to 150 SEK/year.
        </p>

        <p className="text-orange-500 font-semibold">
          Note that this membership is valid for the calendar year, regardless
          of when you apply; for instance, if you pay for 2023, it covers the
          entire year, and subsequent payments will be for the following years,
          such as 2024, and so forth.
        </p>
      </div>

      <div className="flex justify-between flex-col-reverse gap-2">
        <button
          type="button"
          className="w-full bg-gray-500 text-white p-2 rounded mb-2"
          onClick={() => setPage(4)}
        >
          Previous
        </button>
        <button
          type="button"
          className="w-full bg-orange-500 text-white p-2 rounded"
          onClick={() => {
            if (validateForm()) setPage(6);
          }}
        >
          Next
        </button>
      </div>
    </>
  );

  const renderPageSix = () => (
    <>
      <div className="text-center mb-4">
        <div>
          <p className="text-lg mb-4">
            Please confirm your payment to complete the registration.
          </p>
        </div>
        <img
          loading="lazy"
          src="/images/donate.webp"
          alt="QR Code for Swish payment"
          className="mx-auto mb-4"
        />
        <p className="text-lg mb-4">Swish Number: 1233099173</p>
      </div>
      <div className="w-full mb-4 p-2 border rounded flex justify-center">
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            name="paymentConfirmed"
            checked={formData.paymentConfirmed}
            onChange={handleChange}
            className="form-checkbox"
          />
          <span className="ml-2">I have completed the payment</span>
        </label>
      </div>
      <div className="flex justify-between flex-col-reverse gap-2">
        <button
          type="button"
          className="w-full bg-gray-500 text-white p-2 rounded mb-2"
          onClick={() => setPage(5)}
        >
          Previous
        </button>
        <button
          type="submit"
          className="w-full bg-orange-500 text-white p-2 rounded"
        >
          Submit
        </button>
      </div>
    </>
  );

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white md:w-[500px] lg:w-[600px] w-[380px] p-8 rounded shadow-lg relative">
        <IoCloseCircleOutline
          className="absolute top-2 right-2 text-3xl cursor-pointerbg-orange-500"
          onClick={onClose}
        />
        <h2 className="text-2xl font-bold mb-4 text-orange-500">Join Us</h2>
        <form onSubmit={handleSubmit}>
          {page === 1 && renderPageOne()}
          {page === 2 && renderPageTwo()}
          {page === 3 && renderPageThree()}
          {page === 4 && renderPageFour()}
          {page === 5 && renderPageFive()}
          {page === 6 && renderPageSix()}
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default JoinPopup;
