import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Events = () => {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [registeredEvents, setRegisteredEvents] = useState([]);
  const [expandedEvent, setExpandedEvent] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(
          "https://apnbackend.onrender.com/api/events"
        );
        const data = await response.json();

        const now = new Date();
        const upcoming = data.filter((event) => new Date(event.date) >= now);
        console.log(upcoming);

        setUpcomingEvents(upcoming.slice(0, 3)); // Limit to 3 events
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);

  const checkRegistrationStatus = async () => {
    const userEmail = "user@example.com"; // Replace with the actual user email from your auth system
    try {
      const registrations = await Promise.all(
        upcomingEvents.map(async (event) => {
          const response = await fetch(
            `https://apnbackend.onrender.com/api/events/isRegistered/${event._id}/${userEmail}`
          );
          const data = await response.json();
          return data.isRegistered ? event._id : null;
        })
      );
      setRegisteredEvents(registrations.filter(Boolean));
    } catch (error) {
      toast.error("Error checking registration status: " + error.message);
    }
  };

  useEffect(() => {
    checkRegistrationStatus();
  }, [upcomingEvents]);

  const handleToggleExpand = (index) => {
    setExpandedEvent(expandedEvent === index ? null : index);
  };

  const isTextLong = (text) => text.length > 130;

  const isRegistrationOpen = (event) => {
    const now = new Date();
    const registrationStart = new Date(event.registrationStartDate);
    const registrationEnd = new Date(event.registrationEndDate);
    return now >= registrationStart && now <= registrationEnd;
  };

  const handleRegister = (eventId) => {
    navigate(`/register/${eventId}`);
  };

  return (
    <section className="py-20 text-center">
      <div className="container mx-auto w-[90%]">
        <h2 className="text-4xl font-bold mb-4">Upcoming Events</h2>
        {upcomingEvents.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 text-start">
            {upcomingEvents.map((event, index) => (
              <div
                key={event._id}
                className="bg-white h-fit rounded shadow-xl hover:shadow-md p-6 flex flex-col gap-4 relative group"
              >
                <h3 className="text-2xl font-bold mb-2">{event.title}</h3>
                <p>
                  {expandedEvent === index
                    ? event.description
                    : `${event.description.substring(0, 130)}...`}
                </p>
                {isTextLong(event.description) && (
                  <button
                    onClick={() => handleToggleExpand(index)}
                    className="text-[#f46524] font-bold"
                  >
                    {expandedEvent === index ? "Read Less" : "Read More"}
                  </button>
                )}
                <div className="relative">
                  <img
                    loading="lazy"
                    src={event.banner || "/images/default-event.jpg"} // Use default image if banner is not provided
                    alt={event.title || "Event Image"} // Use event title as alt text
                    className="rounded w-full h-[250px] bg-black"
                  />
                </div>
                <p className="mt-2">
                  <strong>Date:</strong>{" "}
                  {new Date(event.date).toLocaleDateString()}
                </p>
                <p className="mb-4">
                  <strong>Registration Ends:</strong>{" "}
                  {new Date(event.registrationEndDate).toLocaleDateString()}
                </p>
                {registeredEvents.includes(event._id) ? (
                  <p className="text-red-600 font-semibold">
                    Already Registered
                  </p>
                ) : isRegistrationOpen(event) ? (
                  <button
                    onClick={() => handleRegister(event._id)}
                    className="py-3 px-6 bg-[#f46524] text-white font-semibold rounded transition-colors duration-300 hover:bg-[#df4d11]"
                  >
                    Register
                  </button>
                ) : (
                  <p className="py-3 px-6 bg-gray-200 text-gray-600 font-semibold rounded">
                    Registration{" "}
                    {new Date() < new Date(event.registrationStartDate)
                      ? "opens"
                      : "closed"}{" "}
                    on{" "}
                    {new Date(event.registrationStartDate).toLocaleDateString()}
                  </p>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="mb-16 w-[90%] mx-auto">
            <div className="grid grid-cols-1 gap-8">
              <div className="bg-white rounded shadow-xl hover:shadow-md cursor-pointer transform transition duration-300 hover:scale-105 p-6 flex flex-col md:flex-row items-center gap-8 h-fit relative group">
                <div className="w-full md:w-1/2">
                  <h3 className="text-2xl font-bold mb-2 capitalize">
                    Stay tuned for our upcoming events
                  </h3>
                  <p>Coming soon! Stay tuned for more details.</p>
                </div>
                <div className="w-full md:w-1/2">
                  <img
                    loading="lazy"
                    src="/images/eventslide3.avif"
                    alt="apn comingsoon events"
                    className="w-full rounded-lg shadow-md"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="mt-8">
          <Link
            to="/events"
            className="bg-[#f46524] text-white font-bold py-3 px-6 rounded"
          >
            View All Events
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Events;
