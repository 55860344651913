import React, { useEffect, useState } from "react";
import axios from "axios";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";

const Program = () => {
  const [upcomingPrograms, setUpcomingPrograms] = useState([]);

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await axios.get(
          "https://apnbackend.onrender.com/api/programs"
        );
        setUpcomingPrograms(response.data);
      } catch (error) {
        console.error("Error fetching programs:", error);
      }
    };

    fetchPrograms();
  }, []);

  const formatDateTime = (dateString, timeString) => {
    const date = new Date(dateString);
    const [hours, minutes] = timeString.split(":");
    date.setHours(hours, minutes);

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    return date.toLocaleString("en-US", options);
  };

  const carouselImages = [
    "/images/programslide.avif",
    "/images/programslide1.avif",
    "/images/programslide2.avif",
  ];

  const programs = [
    {
      title: "Mentorship Program",
      imgSrc: "/images/welocome-to-sweden.webp",
      link: "/programs/welcome-to-sweden",
      imgOnTop: true,
    },
    {
      title: "APN Mentorship Program!",
      imgSrc: "/images/apn-mentorship.webp",
      link: "/programs/mentorship",
    },
  ];

  return (
    <section className="bg-gray-100 pb-20">
      <div className="container mx-auto">
        <div className="relative mb-8">
          <Carousel
            showArrows={false}
            showThumbs={false}
            showStatus={false}
            infiniteLoop
            autoPlay
            interval={3000}
            className="h-[600px] lg:h-[400px]"
          >
            {carouselImages.map((image, index) => (
              <div key={index}>
                <img
                  loading="lazy"
                  src={image}
                  alt={`carousel-${index}`}
                  className="h-[600px] lg:h-[400px] object-cover w-full"
                />
              </div>
            ))}
          </Carousel>
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-80 flex-col gap-2 text-white ">
            <div className="w-[90%] mx-auto">
              <h2 className="text-4xl font-bold">Our Programs</h2>
              <p className="text-lg mt-4">
                Our programs pair professionals of African background with
                experienced mentors in individual mentorship groups, aligning
                individuals based on similar educational backgrounds to assist
                in achieving professional goals. The primary objectives include
                facilitating entry or advancement in the Swedish job market,
                fostering networks between mentees and established
                professionals, and empowering individuals of African background
                in their job-seeking and career endeavors.
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-[90%] mx-auto">
          {programs.map((program, index) => (
            <div
              key={index}
              className="bg-white rounded shadow-xl hover:shadow-md cursor-pointer transform transition duration-300 hover:scale-105 p-6 flex flex-col items-center relative"
            >
              <div className="w-full relative">
                <img
                  loading="lazy"
                  src={program.imgSrc}
                  alt={program.title}
                  className="rounded w-full"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center text-white">
                  {program.imgOnTop ? (
                    <img
                      loading="lazy"
                      src="/images/welocome-to-sweden-on-top.webp"
                      alt=""
                      className="h-[60%]"
                    />
                  ) : (
                    <h3 className="text-2xl font-bold mb-2 mx-auto text-center">
                      {program.title}
                    </h3>
                  )}
                  <Link to={program.link}>
                    <button className="bg-[#f46524] text-white font-bold py-2 px-4 rounded">
                      Learn More
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>

        {upcomingPrograms.length > 0 && (
          <section className="w-[80%] mx-auto my-16">
            <h2 className="text-3xl font-bold mb-10">Upcoming Programs</h2>
            <div className="grid gap-8">
              {upcomingPrograms.map((program, index) => (
                <div
                  key={index}
                  className="p-6 bg-white rounded-lg shadow-md flex flex-col md:flex-row justify-between items-center"
                >
                  <div>
                    <h3 className="text-2xl font-bold">{program.title}</h3>
                    <p className="text-lg">Location: {program.location}</p>
                    <p className="text-lg">
                      {formatDateTime(program.date, program.time)}
                    </p>
                  </div>
                  <div className="text-xl font-bold text-blue-600">
                    {program.price}
                  </div>
                </div>
              ))}
            </div>
          </section>
        )}
      </div>
    </section>
  );
};

export default Program;
