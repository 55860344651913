import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import EventsPage from "./pages/EventsPage";
import AboutPage from "./pages/AboutPage";
import MembershipPage from "./pages/MembershipPage";
import ProgramPage from "./pages/ProgramPage";
import Mentorship from "./pages/programss/mentorship/Mentorship";
import AdminPage from "./pages/AdminPage";
import RegisterEvent from "./pages/RegisterEvent";
import ManageEvents from "./pages/admins-events/ManageEvents";
import EditEvent from "./pages/admins-events/EditEvent";
import WelcomeToSweden from "./pages/programss/welcome-to-sweden/WelcomeToSweden";
import { ToastContainer } from "react-toastify";
import ViewEventRegistrations from "./pages/admins-events/ViewEventRegistrations";

function App() {
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<AboutPage />} path="/about" />
        <Route element={<EventsPage />} path="/events" />
        <Route element={<MembershipPage />} path="/membership" />
        <Route element={<ProgramPage />} path="/programs" />
        <Route
          element={<WelcomeToSweden />}
          path="/programs/welcome-to-sweden"
        />
        <Route element={<Mentorship />} path="/programs/mentorship" />
        <Route path="/register/:eventId" element={<RegisterEvent />} />
        <Route path="/apn/admin/manage-events" element={<ManageEvents />} />
        <Route path="/apn/admin/edit-event/:id" element={<EditEvent />} />
        <Route path="/apn/admin/*" element={<AdminPage />} />
        <Route
          path="/apn/admin/view-event-registrations"
          element={<ViewEventRegistrations />}
        />{" "}
      </Routes>
    </>
  );
}

export default App;
