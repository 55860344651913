import React from "react";
import { Link } from "react-router-dom";

const AdminDashboard = ({ onLogout }) => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center py-10">
      <div className="w-full max-w-4xl p-8 bg-white shadow-lg rounded-lg">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-800">
            Welcome to the Admin Dashboard
          </h2>
          <button
            onClick={onLogout}
            className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded transition duration-300"
          >
            Logout
          </button>
        </div>
        <nav className="bg-gray-50 p-4 rounded-lg shadow-inner">
          <ul className="grid grid-cols-2 gap-6">
            <li className="mb-2 sm:mb-0">
              <Link
                to="/apn/admin/view-users"
                className="block w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-300 text-center"
              >
                View Registered Members
              </Link>
            </li>
            <li className="mb-2 sm:mb-0">
              <Link
                to="/apn/admin/post-event"
                className="block w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-300 text-center"
              >
                Post Events
              </Link>
            </li>
            <li className="mb-2 sm:mb-0">
              <Link
                to="/apn/admin/manage-events"
                className="block w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-300 text-center"
              >
                Manage Events
              </Link>
            </li>
            <li className="mb-2 sm:mb-0">
              <Link
                to="/apn/admin/view-event-registrations"
                className="block w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-300 text-center"
              >
                View Event Registrations
              </Link>
            </li>
            <li className="mb-2 sm:mb-0">
              <Link
                to="/apn/admin/post-program"
                className="block w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-300 text-center"
              >
                Post Programs
              </Link>
            </li>
            <li className="mb-2 sm:mb-0">
              <Link
                to="/apn/admin/manage-programs"
                className="block w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-300 text-center"
              >
                Manage Programs
              </Link>
            </li>
            <li className="mb-2 sm:mb-0">
              <Link
                to="/apn/admin/mentor-mentee-applications"
                className="block w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-300 text-center"
              >
                Mentor/Mentee Applications
              </Link>
            </li>
            <li className="mb-2 sm:mb-0">
              <Link
                to="/apn/admin/newsletter-subscriptions"
                className="block w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-300 text-center"
              >
                View Newsletter Subs
              </Link>
            </li>
            <li className="mb-2 sm:mb-0">
              <Link
                to="/apn/admin/welcome-to-sweden-guide-download-list"
                className="block w-full capitalize bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-300 text-center"
              >
                Welcome to sweden download list
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default AdminDashboard;
