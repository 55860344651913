import React from "react";
import { FaInstagram, FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const socialMediaLinks = [
    {
      icon: <FaInstagram size={20} className=" cursor-pointer" />,
      url: "https://www.instagram.com/africanprofessionalssweden",
    },
    {
      icon: <FaFacebook size={20} className=" cursor-pointer" />,
      url: "https://facebook.com/APNSweden",
    },
    {
      icon: <FaTwitter size={20} className=" cursor-pointer" />,
      url: "https://twitter.com/apnsweden",
    },
    {
      icon: <FaLinkedin size={20} className=" cursor-pointer" />,
      url: "https://www.linkedin.com/company/apnsweden/mycompany",
    },
  ];

  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto flex flex-col gap-4 md:gap-0 md:flex-row justify-between items-center w-[90%]">
        <p>&copy; {currentYear} African Professionals Network, Sweden</p>
        <div className="flex justify-center space-x-4 mb-8">
          {socialMediaLinks.map((social, index) => (
            <a
              key={index}
              href={social.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {social.icon}
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
