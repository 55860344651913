import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

const EditProgram = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    location: "",
    date: "",
    time: "",
    price: "",
  });

  useEffect(() => {
    const fetchProgram = async () => {
      try {
        const response = await axios.get(
          `https://apnbackend.onrender.com/api/programs/${id}`
        );
        setFormData(response.data);
      } catch (error) {
        console.error("Error fetching program:", error);
      }
    };
    fetchProgram();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `https://apnbackend.onrender.com/api/programs/${id}`,
        formData
      );
      alert("Program updated successfully!");
      navigate("/apn/admin/manage-programs");
    } catch (error) {
      console.error("Error updating program:", error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center py-10">
      <div className="w-full max-w-4xl p-8 bg-white shadow-lg rounded-lg">
        {" "}
        <div className="flex items-center justify-between">
          <h2 className="text-2xl font-bold mb-6">Edit Program</h2>
          <button
            type="button"
            onClick={() => navigate("/apn/admin/dashboard")}
            className="bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded transition duration-300"
          >
            Back to Dashboard
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            placeholder="Program Title"
            className="w-full p-2 border rounded"
            required
          />

          <input
            type="text"
            name="location"
            value={formData.location}
            onChange={handleChange}
            placeholder="Location"
            className="w-full p-2 border rounded"
            required
          />
          <input
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            required
          />
          <input
            type="time"
            name="time"
            value={formData.time}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            required
          />
          <input
            type="text"
            name="price"
            value={formData.price}
            onChange={handleChange}
            placeholder="Price"
            className="w-full p-2 border rounded"
            required
          />

          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300"
          >
            Update Program
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditProgram;
