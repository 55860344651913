import React, { useState } from "react";
import axios from "axios";

const ApplicationModal = ({ isOpen, onClose, role }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [experience, setExperience] = useState("");
  const [goals, setGoals] = useState("");
  const [error, setError] = useState("");
  const [formError, setFormError] = useState("");

  const handleNext = () => {
    if (currentStep === 1 && (!name || !email || !phone)) {
      setFormError("Please fill out all fields.");
      return;
    }
    setFormError("");
    setCurrentStep(currentStep + 1);
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setPhone("");
    setExperience("");
    setGoals("");
    setCurrentStep(1);
    setError("");
    setFormError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://apnbackend.onrender.com/api/applications",
        {
          role,
          name,
          email,
          phone,
          experience,
          goals,
        }
      );
      console.log(response.data);
      resetForm();
      onClose();
    } catch (err) {
      setError(err.message);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4">Apply to be a {role}</h2>
        {error && <p className="text-red-500">{error}</p>}
        {formError && <p className="text-red-500">{formError}</p>}
        <form onSubmit={handleSubmit}>
          {currentStep === 1 && (
            <>
              <div className="mb-4">
                <label className="block text-gray-700">Name</label>
                <input
                  type="text"
                  className="w-full px-3 py-2 border rounded"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Email</label>
                <input
                  type="email"
                  className="w-full px-3 py-2 border rounded"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Phone</label>
                <input
                  type="text"
                  className="w-full px-3 py-2 border rounded"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
              <div className="flex justify-between">
                <button
                  type="button"
                  className="py-2 px-4 bg-blue-500 text-white rounded"
                  onClick={handleNext}
                >
                  Next
                </button>
                <button
                  type="button"
                  className="py-2 px-4 bg-gray-500 text-white rounded"
                  onClick={() => {
                    resetForm();
                    onClose();
                  }}
                >
                  Cancel
                </button>
              </div>
            </>
          )}
          {currentStep === 2 && (
            <>
              <div className="mb-4">
                <label className="block text-gray-700">Experience</label>
                <textarea
                  className="w-full px-3 py-2 border rounded"
                  value={experience}
                  onChange={(e) => setExperience(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Goals</label>
                <textarea
                  className="w-full px-3 py-2 border rounded"
                  value={goals}
                  onChange={(e) => setGoals(e.target.value)}
                  required
                />
              </div>
              <div className="flex justify-between">
                <button
                  type="button"
                  className="py-2 px-4 bg-gray-500 text-white rounded"
                  onClick={handlePrevious}
                >
                  Previous
                </button>
                <button
                  type="submit"
                  className="py-2 px-4 bg-blue-500 text-white rounded"
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default ApplicationModal;
