import axios from "axios";
import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";

const DownloadGuideList = () => {
  const [entries, setEntries] = useState([]);

  useEffect(() => {
    const fetchEntries = async () => {
      try {
        const response = await axios.get(
          "https://apnbackend.onrender.com/api/downloadGuide"
        );
        setEntries(response.data);
      } catch (error) {
        console.error("Error fetching entries:", error);
      }
    };

    fetchEntries();
  }, []);

  const downloadPDF = () => {
    const doc = new jsPDF();

    doc.autoTable({
      head: [["Full Name", "Email", "Category"]],
      body: entries.map((entry) => [
        entry.fullName,
        entry.email,
        entry.category,
      ]),
    });

    doc.save("download-guide-list.pdf");
  };
  const navigate = useNavigate();
  return (
    <div className="container mx-auto my-16 w-[80%] ">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-bold mb-6">Download Guide List </h2>
        <button
          type="button"
          onClick={() => navigate("/apn/admin/dashboard")}
          className="bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded transition duration-300"
        >
          Back to Dashboard
        </button>
      </div>
      <button
        onClick={downloadPDF}
        className="py-3 px-6 mb-4 bg-[#4C469D] hover:bg-white hover:text-[#4C469D] text-white rounded-lg"
      >
        Download as PDF
      </button>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white rounded-lg shadow-md">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">Full Name</th>
              <th className="py-2 px-4 border-b">Email</th>
              <th className="py-2 px-4 border-b">Category</th>
            </tr>
          </thead>
          <tbody>
            {entries.map((entry, index) => (
              <tr key={index} className="hover:bg-gray-100">
                <td className="py-2 px-4 border-b">{entry.fullName}</td>
                <td className="py-2 px-4 border-b">{entry.email}</td>
                <td className="py-2 px-4 border-b">{entry.category}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DownloadGuideList;
