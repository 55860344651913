import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
import JoinPopup from "../components/JoinPopup";
import { Carousel } from "react-responsive-carousel";

const EventsPage = () => {
  const [showJoinPopup, setShowJoinPopup] = useState(false);
  const [expandedEvent, setExpandedEvent] = useState(null);
  const [expandedCatalogue, setExpandedCatalogue] = useState({});
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const targetDivRef = useRef(null);

  const scrollToDiv = () => {
    if (targetDivRef.current) {
      targetDivRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(
          "https://apnbackend.onrender.com/api/events"
        );
        const data = await response.json();

        const now = new Date();
        const upcoming = data.filter((event) => new Date(event.date) >= now);

        setUpcomingEvents(upcoming);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);

  const handleJoinClick = () => {
    setShowJoinPopup(true);
  };

  const handleClosePopup = () => {
    setShowJoinPopup(false);
  };

  const handleToggleExpandEvent = (index) => {
    setExpandedEvent(expandedEvent === index ? null : index);
  };

  const handleToggleExpandCatalogue = (index) => {
    setExpandedCatalogue({
      ...expandedCatalogue,
      [index]: !expandedCatalogue[index],
    });
  };

  const isTextLong = (text) => text.length > 130;

  const formatDate = (date) => {
    return date ? new Date(date).toLocaleDateString() : "TBD";
  };

  const handleRegister = (eventId) => {
    navigate(`/register/${eventId}`);
  };

  const catalogue = [
    {
      id: 1,
      image: "/images/Protalk-logo.png",
      description:
        "An interactive expert-led discussion focusing on exploring subject of high technical and intellectual impact. The discussion could also be set as a moderated panel discussion. Topics are curated from the network and experts in such topics sourced and invited to drive conversation. With the Protalk series, we want to showcase the wealth of intellectual capital within the network while also providing platform for our network to stay informed and updated on this key topics. This is a premium event, participants would purchase tickets to particiapte",
      title: "The ProTalk ",
    },
    {
      id: 2,
      image: "/images/career-fair.png",
      description:
        "The Career fair is an event aimed at bridging the gap between the employer and the talent. The program is designed to connect with top talents of African descent irrespective of the stage of career they are. With this program we bring the talent to the employer, create space for networking, interaction, brand showcasing and insightful conversations. The fair is about showcasing excellence, its about building affiliations and paving the way for top talents of African origin to build awareness, gain assess and cultivate the network to attain that next career move. This is a premium event, participants would purchase tickets to particiapte",
      title: "career fair talk",
    },
    {
      id: 3,
      image: "/images/anevening-with1.png",
      description:
        "A roundtable dialogue set in form of old times fireside chat. The objective of this event is to celebrate successful people of African decent and showcase their work and achievments. Its an evening of storytelling, an evening to journey with the individual through their individual learnings and experiences. This event is a relaxed, safe space for participants to interact and learn from individual experience.  We will continously keep and update database of people of african decent in Sweden to showcase through this platform.",
      title: "An evening with APN",
    },
    {
      id: 4,
      image: "/images/Mingle1.png",
      description:
        "A roundtable dialogue set in form of old times fireside chat. The objective of this event is to celebrate successful people of African decent and showcase their work and achievments. Its an evening of storytelling, an evening to journey with the individual through their individual learnings and experiences. This event is a relaxed, safe space for participants to interact and learn from individual experience.  We will continously keep and update database of people of african decent in Sweden to showcase through this platform.",
      title: "The APN Mingle  ",
    },
  ];

  const carouselImages = [
    "/images/eventslide.avif",
    "/images/eventslide2.avif",
    "/images/eventslide3.avif",
  ];

  const isRegistrationOpen = (event) => {
    const now = new Date();
    const registrationStart = new Date(event.registrationStartDate);
    const registrationEnd = new Date(event.registrationEndDate);
    return now >= registrationStart && now <= registrationEnd;
  };

  return (
    <>
      <Header onJoinClick={handleJoinClick} />
      <section className="mb-20 text-center bg-[#DFDDEA]">
        <div className="container bg-[#DFDDEA] mx-auto">
          <div className="relative mb-8">
            <Carousel
              showArrows={false}
              showThumbs={false}
              showStatus={false}
              infiniteLoop
              autoPlay
              interval={3000}
              className="h-[600px] lg:h-[400px]"
            >
              {carouselImages.map((image, index) => (
                <div key={index}>
                  <img
                    loading="lazy"
                    src={image}
                    alt={`carousel-${index}`}
                    className="h-[600px] lg:h-[400px] object-cover w-full"
                  />
                </div>
              ))}
            </Carousel>
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-80 flex-col gap-2 text-white">
              <div className="w-[90%] mx-auto">
                <h2 className="text-4xl font-bold">Event Catalogue</h2>
                <p className="text-lg mt-4">
                  Connecting & empowering through relatable dialogues
                </p>
                <button
                  onClick={scrollToDiv}
                  className="py-3 px-6 mt-4 bg-[#f46524] text-white font-semibold rounded transition-colors duration-300 hover:bg-[#df4d11]"
                >
                  Check Our Upcoming Events
                </button>
              </div>
            </div>
          </div>

          <section className="mb-16 py-16 bg-[#DFDDEA] text-white gap-4 p-6 w-[90%] mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {catalogue.map((cata, index) => (
                <div
                  key={index}
                  className="bg-white h-fit rounded shadow-xl hover:shadow-md cursor-pointer transform transition duration-300 hover:scale-105 p-6 flex flex-col items-center relative"
                >
                  <div className="w-full">
                    <img
                      loading="lazy"
                      src={cata.image}
                      alt={cata.title}
                      className="rounded w-full bg-black"
                    />
                  </div>
                  <div className="text-start text-black mt-4">
                    <h3 className=" capitalize text-2xl font-bold mb-2">
                      {cata.title}
                    </h3>
                    <p>
                      {expandedCatalogue[index]
                        ? cata.description
                        : `${cata.description.substring(0, 130)}...`}
                    </p>
                    {isTextLong(cata.description) && (
                      <button
                        onClick={() => handleToggleExpandCatalogue(index)}
                        className="text-[#f46524] font-bold"
                      >
                        {expandedCatalogue[index] ? "Read Less" : "Read More"}
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </section>

          <div className="my-16 w-[90%] mx-auto" ref={targetDivRef}>
            <h3 className="text-4xl font-bold text-start my-4">
              Upcoming Events
            </h3>
            <div className="grid md:grid-cols-2 gap-8 text-start">
              {upcomingEvents.length > 0 &&
                upcomingEvents.map((event, index) => {
                  return (
                    <div
                      key={index}
                      className="bg-white rounded shadow-xl hover:shadow-md cursor-pointer transform transition duration-300 hover:scale-105 p-6 flex flex-col gap-4 h-fit relative group"
                    >
                      <div className="relative">
                        <img
                          loading="lazy"
                          src={
                            event.banner ||
                            event.imgSrc ||
                            "/images/default-event.jpg"
                          }
                          alt={event.imgAlt || "Event Image"}
                          className="rounded-lg shadow-md w-full bg-black lg:h-[400px]"
                        />
                      </div>
                      <div>
                        <h3 className="text-2xl font-bold mb-2">
                          {event.title}
                        </h3>
                        <p className=" text-wrap">
                          {expandedEvent === index
                            ? event.description
                            : `${event.description.substring(0, 130)}...`}
                        </p>
                        {isTextLong(event.description) && (
                          <button
                            onClick={() => handleToggleExpandEvent(index)}
                            className="text-[#f46524] font-bold"
                          >
                            {expandedEvent === index
                              ? "Read Less"
                              : "Read More"}
                          </button>
                        )}
                      </div>

                      <div className="text-gray-500 flex justify-between">
                        <p className="italic font-semibold">
                          Date: {formatDate(event.date)}
                        </p>
                      </div>

                      <div className="flex justify-between items-center">
                        {isRegistrationOpen(event) ? (
                          <button
                            onClick={() => handleRegister(event._id)}
                            className="py-3 px-6 bg-[#f46524] text-white font-semibold rounded transition-colors duration-300 hover:bg-[#df4d11]"
                          >
                            Register
                          </button>
                        ) : (
                          <p className="py-3 px-6 bg-gray-200 text-gray-600 font-semibold rounded">
                            Registration{" "}
                            {new Date() < new Date(event.registrationStartDate)
                              ? "opens"
                              : "closed"}{" "}
                            on{" "}
                            {new Date(
                              event.registrationStartDate
                            ).toLocaleDateString()}
                          </p>
                        )}
                        <p className="text-sm italic text-gray-600">
                          {event.location}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>

            {upcomingEvents.length < 1 && (
              <div className="bg-white rounded shadow-xl hover:shadow-md cursor-pointer transform transition duration-300 hover:scale-105 p-6 flex flex-col md:flex-row items-center gap-8 h-fit relative group">
                <div className="w-full md:w-1/2">
                  <h3 className="text-2xl font-bold mb-2 capitalize">
                    Stay tuned for our upcoming events
                  </h3>
                  <p>Coming soon! Stay tuned for more details.</p>
                </div>
                <div className="w-full md:w-1/2">
                  <img
                    loading="lazy"
                    src="/images/eventslide3.avif"
                    alt="apn comingsoon events"
                    className="w-full rounded-lg shadow-md"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <section className="bg-black py-10">
          <div className="w-[90%] mx-auto my-10">
            <div className="text-white p-8 rounded-lg shadow-md flex flex-col lg:flex-row items-center gap-8 text-start">
              <div className="w-full lg:w-1/2">
                <h3 className="lg:text-6xl text-5xl mb-4 font-thin">
                  ABOUT OUR <span className="font-semibold">PROGRAM</span>
                </h3>
                <p className="mb-4">
                  This is an induction event aimed at supporting newly
                  immigrated people of African descent integrate to the Swedish
                  society. We will bring subject experts to drive topics of
                  interest, host on the spot consultancy and resolution
                  services.
                </p>
                <p>
                  This is an APN Sweden event dedicated solely to integration
                  related topics. The event is not exclusive to people who moved
                  in the same year but covers integration related topics with
                  depth that can fit for anyone in as much the topic is an area
                  of need.
                </p>
              </div>
              <div className="w-full lg:w-1/2 flex flex-col gap-3 justify-center">
                <img
                  loading="lazy"
                  src="/images/welllllll.jpg"
                  alt="Welcome to Sweden"
                  className="rounded-lg shadow-md"
                />

                <Link
                  to={"/programs/welcome-to-sweden"}
                  className="bg-[#f46524] p-2 text-center rounded w-full hover:bg-opacity-50 mx-auto"
                >
                  Learn more
                </Link>
              </div>
            </div>
          </div>
        </section>
      </section>
      <Contact />
      <Footer />

      <JoinPopup show={showJoinPopup} onClose={handleClosePopup} />
    </>
  );
};

export default EventsPage;
