import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import AboutUs from "../components/AboutUs";
import Events from "../components/Events";
import Programs from "../components/Programs";
import Footer from "../components/Footer";
import JoinPopup from "../components/JoinPopup";
import Contact from "../components/Contact";

const Home = () => {
  const [showJoinPopup, setShowJoinPopup] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleJoinClick = () => {
    setShowJoinPopup(true);
  };

  const handleClosePopup = () => {
    setShowJoinPopup(false);
  };

  return (
    <div>
      <Header onJoinClick={handleJoinClick} />
      <Hero onJoinClick={handleJoinClick} />
      <AboutUs />
      <Events />
      <Programs />
      <Contact />
      <Footer />
      <JoinPopup show={showJoinPopup} onClose={handleClosePopup} />
    </div>
  );
};

export default Home;
