import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const programFeatures = [
  {
    title: "Welcome to Sweden",
    subtitle: "The Hub",
    description:
      "A self-service portal containing essential information useful for African professionals who are preparing to move to Sweden and/or already living in Sweden.",
    imageUrl: "/images/thehub.jpg",
  },
  {
    title: "Welcome to Sweden",
    subtitle: "Ask an Expat",
    description:
      "Do you require guidance and personalized support with questions or situations which may be unique to you as part of your integration? Explore our list of Subject Matter Experts(SMEs), and request one-on-one support with them.",
    imageUrl: "/images/ask-an-expat.jpg",
    link: true,
  },
  {
    title: "Welcome to Sweden",
    subtitle: "Integration Event",
    description:
      "This is an induction event aimed at supporting newly arrived Expats and immigrants of different context integrate to the Swedish society. We will bring subject experts to drive topics of interest, host on the spot consultancy and resolution services.",
    imageUrl: "/images/integration-event.jpg",
  },
];

const Contents = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    category: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const [upcomingPrograms, setUpcomingPrograms] = useState([]);

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await axios.get(
          "https://apnbackend.onrender.com/api/programs"
        );
        setUpcomingPrograms(response.data);
      } catch (error) {
        console.error("Error fetching programs:", error);
      }
    };

    fetchPrograms();
  }, []);

  const formatDateTime = (dateString, timeString) => {
    const date = new Date(dateString);
    const [hours, minutes] = timeString.split(":");
    date.setHours(hours, minutes);

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    return date.toLocaleString("en-US", options);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate form data
    if (formData.fullName && formData.email && formData.category) {
      try {
        // Save form data to backend
        await axios.post(
          "https://apnbackend.onrender.com/api/downloadGuide",
          formData
        );

        // Trigger the download
        const link = document.createElement("a");
        link.href = "/Welcome-to-Sweden-Booklet.pdf"; // Path to your PDF file in the public folder
        link.download = "Welcome-to-Sweden-Booklet.pdf";
        link.click();

        // Optionally, you can also clear the form
        setFormData({
          fullName: "",
          email: "",
          category: "",
        });
      } catch (error) {
        console.error("Error saving form data:", error);
        alert("Failed to save form data.");
      }
    } else {
      alert("Please fill out all fields.");
    }
  };

  return (
    <div className="container mx-auto">
      <section
        className="text-center mb-16 md:h-[50vh] lg:h-[80vh]"
        style={{
          backgroundImage: `url('/images/welocome-to-sweden.webp')`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <img
          loading="lazy"
          src="/images/welocome-to-sweden-on-top.webp"
          alt=""
          className="mx-auto"
        />
      </section>

      <section className="mb-16 w-[80%] mx-auto grid md:grid-cols-2 gap-6">
        <div className="flex flex-col gap-3">
          <h2 className="text-3xl font-bold mb-4">
            Your one-stop support system to a fulfilling integration
          </h2>
          <p>
            As part of our unwavering commitment to creating a supportive
            environment for every expat, we introduce the APN Welcome to Sweden
            program, aimed at supporting newly arrived African expats and
            immigrants in Sweden by providing helpful, timely and actionable
            tips/information, thereby fostering better integration upon arrival.
          </p>
        </div>
        <img
          loading="lazy"
          src="/images/Welcome to Sweden Page (10)_edited_edited111.jpg"
          alt=""
          className="shadow-xl rounded"
        />
      </section>

      <section className="w-[80%] mx-auto">
        <h2 className="text-3xl font-bold my-10">Program features</h2>
        <div className="grid lg:grid-cols-3 gap-8">
          {programFeatures.map((feature, index) => (
            <div
              key={index}
              className="flex flex-col text-start items-start shadow-xl h-fit hover:shadow-md cursor-pointer transform transition duration-300 hover:scale-105 p-6 rounded-md"
            >
              <h3 className="text-2xl font-bold mb-2">{feature.title}</h3>
              <h4 className="text-xl mb-2">{feature.subtitle}</h4>{" "}
              <img
                loading="lazy"
                alt={feature.subtitle}
                src={feature.imageUrl}
                className="mb-4 w-fit shadow-xl rounded"
              />
              <p>{feature.description}</p>
              {feature.link && (
                <Link className="py-3 px-6 bg-[#4C469D] mt-6 text-white">
                  Ask an Expat
                </Link>
              )}
            </div>
          ))}
        </div>
      </section>

      {/* Add this new section after the program features grid */}
      <section className="bg-[#6441a4] my-16">
        <div className="md:w-[90%] lg:w-[75%] mx-auto  p-8 rounded-lg text-white gap-4 grid md:grid-cols-2">
          <img
            loading="lazy"
            src="/images/thebook.jpg"
            alt=""
            className="h-[400px] rounded-md mx-auto"
          />

          <div className="flex flex-col">
            <div className="flex-1 mb-4 md:mb-0">
              <h2 className="text-3xl font-bold">
                Welcome to Sweden - Booklet
              </h2>
              <p className="text-lg">
                Download current issue of the welcome booklet
              </p>
            </div>
            <div className="flex-1">
              <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder="Full name"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                  className="p-3 text-black rounded-lg"
                />
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="p-3 text-black rounded-lg"
                />
                <select
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  className="p-3 rounded-lg text-black"
                  required
                >
                  <option value="">Choose an option</option>
                  <option value="Student">Student</option>
                  <option value="Employed">Employed</option>
                  <option value="Spouse">Spouse</option>
                  <option value="Job seeking">Job seeking</option>
                  <option value="Entrepreneur">Entrepreneur</option>
                </select>
                <button
                  type="submit"
                  className="py-3 px-6 bg-[#4C469D] hover:bg-white hover:text-[#4C469D] text-white rounded-lg"
                >
                  Download
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      {upcomingPrograms.length > 0 && (
        <section className="w-[80%] mx-auto my-16">
          <h2 className="text-3xl font-bold mb-10">Upcoming Programs</h2>
          <div className="grid gap-8">
            {upcomingPrograms.map((program, index) => (
              <div
                key={index}
                className="p-6 bg-white rounded-lg shadow-md flex flex-col md:flex-row justify-between items-center"
              >
                <div>
                  <h3 className="text-2xl font-bold">{program.title}</h3>
                  <p className="text-lg">Location: {program.location}</p>
                  <p className="text-lg">
                    {formatDateTime(program.date, program.time)}
                  </p>
                </div>
                <div className="text-xl font-bold text-blue-600">
                  {program.price}
                </div>
              </div>
            ))}
          </div>
        </section>
      )}
    </div>
  );
};

export default Contents;
